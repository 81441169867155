import pdfMake from 'pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import dayjs from 'dayjs'
pdfMake.vfs = pdfFonts.pdfMake.vfs

class QRCodeReport {
  async call(equipments) {

    const content = []
    const tamanho = equipments.length > 4 ? 4 : equipments.length
    const qtd = parseInt(equipments.length / 4)

    for (let j = 0; j < qtd; j++) {
      let line = []

      for (let i = 0; i < tamanho; i++) {
        let equipment = equipments.pop()
        line.push({
          width: 40 + (i + 1) * 10,
          margin: [(i + 1) * 10, 0, 0, 0],
          stack: [
            { text: equipment.name, fontSize: 8 },
            {
              width: 60,

              qr: equipment.name, fit: '60'
            },
          ],
          unbreakable: true
        })

        line.push({
          margin: [10, 20, 0, 0],
          image: 'snow',
          width: 60,
          height: 20
        })
      }
      content.push({
        margin: 10,
        columns: line,
      })
    }
    try {
      var docDefinition = {
        pageMargins: [15, 25, 25, 35],
        header: function () {
          return []
        },
        footer: function (currentPage, pageCount) {
          return {
            margin: 10,
            columns: [
              {
                fontSize: 9,
                text: [
                  {
                    text:
                      '© Astra inovacoes. Pagina ' +
                      currentPage.toString() +
                      ' de ' +
                      pageCount + ' | ' + dayjs().format("DD/MM/YYYY - HH:mm"),
                  },
                ],
                alignment: 'center',
              },
            ],
          }
        },
        pageOrientation: 'portrait',
        content: content,
        images: {
          snow: window.location.href + window.imageLogin,
        }
      }
      pdfMake.createPdf(docDefinition).open()
    } catch (error) {
      console.log(error);
    }


  }
}

export default QRCodeReport