<template>
  <div>
    <v-data-table
      class="astra-table"
      dense
      height="74vh"
      fixed-header
      :headers="controller.headers"
      :options.sync="controller.options"
      :server-items-length="controller.equipmentPagination.total"
      :items="controller.equipmentPagination.items"
      :loading="controller.loading"
      :footer-props="{
        'items-per-page-options': [40, 80],
      }"
      show-select
      v-model="controller.selectedItems"
      return-object
    >
      <template v-slot:[`item.active`]="{ item }">
        <v-checkbox
          color="primary"
          class="mt-0 pt-0"
          v-model="item.active"
          hide-details
          readonly
          dense
        ></v-checkbox>
      </template>
      <template v-slot:[`item.createdDate`]="{ item }">
        {{ dayjs(item.createdDate).format("DD/MM/YYYY HH:mm") }}
      </template>
      <template v-slot:[`item.updatedDatePlate`]="{ item }">
        {{
          item.updatedDatePlate
            ? dayjs(item.updatedDatePlate).format("DD/MM/YYYY HH:mm")
            : ""
        }}
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <div>
          <v-btn
            icon
            @click="controller.updateVehicleOpen(item)"
            class="px-2"
            v-if="hadPermission(8,2)"
          >
            <v-icon
              color="#C0CA33"
              small
            >mdi-car-connected</v-icon>
          </v-btn>
          <v-btn
            v-if="hadPermission(6,1)"
            icon
            class="px-2"
            @click="controller.generateQrCodeOpen(item)"
          >
            <v-icon
              color="#008000"
              small
            >mdi-qrcode</v-icon>
          </v-btn>
          <v-btn
            icon
            @click="controller.change(item)"
            class="px-2"
            v-if="hadPermission(2,2)"
          >
            <v-icon
              color="editBtn"
              small
            >mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            icon
            @click="controller.delete(item)"
            class="px-2"
            v-if="hadPermission(2,3)"
          >
            <v-icon
              color="deleteBtn"
              small
            >mdi-delete</v-icon>
          </v-btn>
        </div>
      </template>

      <template v-slot:top>
        <v-row
          class="pa-3"
          no-gutters
        >
          <v-col
            :class="
              $vuetify.breakpoint.smAndDown
                ? 'py-3 d-flex align-center justify-center'
                : 'd-flex align-center'
            "
            cols="12"
            sm="12"
            md="4"
            lg="4"
            xl="4"
          >
            <span class="table-title">Equipamentos</span>
            <div
              class="pl-4 d-flex align-center"
              v-if="hadPermission(0,0)"
            >
              <span class="counter-title">Com placa:</span>
              <div class="px-2 counter-chip">
                {{ controller.totalEquipmentsPlate.withPlate }}
              </div>
              <span class="pl-2 counter-title">Sem placa:</span>
              <div class="px-2 counter-chip">
                {{ controller.totalEquipmentsPlate.withoutPlate }}
              </div>
            </div>
          </v-col>
          <v-col
            :class="
              $vuetify.breakpoint.smAndDown
                ? 'd-flex flex-column align-center justify-center'
                : 'd-flex align-center justify-end'
            "
            cols="12"
            sm="12"
            md="8"
            lg="8"
            xl="8"
          >
            <div style="width: 200px">
              <v-select
                outlined
                dense
                color="primary"
                v-model="controller.statusSelected"
                :items="controller.statusList"
                hide-details
                class="pa-2 search-tf"
                @change="controller.changeStatus()"
                item-text="text"
                item-value="value"
              ></v-select>
            </div>
            <div style="width: 300px">
              <v-text-field
                class="pa-2 search-tf"
                outlined
                color="primary"
                hide-details
                dense
                placeholder="Pesquisar placa/serial"
                v-model="controller.search"
              ></v-text-field>
            </div>
            <v-btn
              v-if="controller.selectedItems.length > 0 && hadPermission(2,2)"
              class="mr-2 action-btn d-flex justify-center"
              height="40"
              color="#039BE5"
              tile
              @click="controller.dialogUpdateClient = true"
            >
              <v-icon left>mdi-account-convert</v-icon>
              Atualizar Cliente
            </v-btn>
            <v-btn
              v-if="controller.selectedItems.length > 3 && hadPermission(2,2)"
              class="mr-2 action-btn d-flex justify-center"
              height="40"
              color="#7986CB"
              tile
              @click="controller.qrCode()"
            >
              <v-icon left>mdi-qrcode</v-icon>
              Imprimir QR-Code
            </v-btn>
            <v-btn
              class="action-btn d-flex justify-center white--text"
              height="40"
              width="120"
              color="green"
              tile
              @click.stop="controller.exportExcel()"
              :loading="controller.loadingBtnExcel"
            >
              <v-icon left>mdi-file-excel-outline</v-icon>
              Excel
            </v-btn>
            <v-dialog
              max-width="600"
              scrollable
              persistent
              v-model="controller.dialog"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="ma-2 action-btn d-flex justify-center"
                  height="40"
                  width="120"
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  tile
                  @click.stop="controller.open()"
                  v-if="hadPermission(2,1)"
                >
                  <v-icon left>mdi-plus</v-icon>
                  Adicionar
                </v-btn>
              </template>
              <v-card class="crud-card">
                <v-card-title>
                  <v-spacer></v-spacer>
                  <span class="table-title">Equipamento</span>
                  <v-spacer></v-spacer>
                </v-card-title>
                <v-card-text class="pt-3 pb-1">
                  <v-form
                    ref="crud"
                    lazy-validation
                  >
                    <v-row no-gutters>
                      <v-col
                        cols="12"
                        class="d-flex justify-center"
                      >
                        <v-switch
                          v-model="controller.form.active"
                          inset
                          label="Ativo"
                        ></v-switch>
                      </v-col>
                      <v-col>
                        <div class="form-input-group">
                          <v-text-field
                            class="search-tf"
                            single-line
                            id="name"
                            :rules="[(v) => !!v || 'Campo obrigatório']"
                            background-color="transparent"
                            outlined
                            required
                            dense
                            placeholder="Serial"
                            v-model="controller.form.name"
                          />
                          <label for="name">Serial</label>
                        </div>
                        <div class="form-input-group">
                          <v-autocomplete
                            class="search-tf"
                            single-line
                            id="model"
                            :rules="[(v) => !!v || 'Campo obrigatório']"
                            background-color="transparent"
                            outlined
                            required
                            dense
                            placeholder="Modelo"
                            v-model="controller.form.modelID"
                            :search-input.sync="controller.searchModel"
                            :items="controller.modelPagination.items"
                            item-text="name"
                            item-value="id"
                          />
                          <label for="model">Modelo</label>
                        </div>
                        <div class="form-input-group">
                          <v-autocomplete
                            class="search-tf"
                            single-line
                            id="vehicle"
                            background-color="transparent"
                            outlined
                            required
                            dense
                            placeholder="Veículo"
                            v-model="controller.form.vehicleID"
                            :search-input.sync="controller.searchVehicle"
                            :items="controller.vehiclePagination.items"
                            item-text="plate"
                            item-value="id"
                          />
                          <label for="vehicle">Veículo</label>
                        </div>
                        <div class="form-input-group">
                          <v-select
                            class="search-tf"
                            single-line
                            id="equipmentType"
                            :rules="[(v) => !!v || 'Campo obrigatório']"
                            background-color="transparent"
                            outlined
                            required
                            dense
                            placeholder="Tipo do Equipamento"
                            v-model="controller.form.equipmentTypeID"
                            :items="controller.equipmentTypePagination.items"
                            item-text="name"
                            item-value="id"
                          />
                          <label for="equipmentType">Tipo do Equipamento</label>
                        </div>
                        <div
                          class="form-input-group"
                          v-if="controller.form.equipmentTypeID == 2 || controller.form.equipmentTypeID == 3"
                        >
                          <v-text-field
                            class="search-tf"
                            single-line
                            id="mac"
                            :rules="[(v) => !!v || 'Campo obrigatório']"
                            background-color="transparent"
                            outlined
                            required
                            dense
                            placeholder="Mac"
                            v-model="controller.form.mac"
                          />
                          <label for="mac">Mac</label>
                        </div>
                        <div
                          class="form-input-group"
                          v-if="hadPermission(0,0)"
                        >
                          <v-autocomplete
                            class="search-tf"
                            single-line
                            id="client"
                            background-color="transparent"
                            outlined
                            dense
                            placeholder="Cliente"
                            v-model="controller.form.clientID"
                            :search-input.sync="controller.searchClient"
                            :items="controller.clientPagination.items"
                            item-text="name"
                            item-value="id"
                          />
                          <label for="client">Cliente</label>
                        </div>
                        <div
                          class="form-input-group"
                        >
                          <v-textarea
                            class="search-tf"
                            single-line
                            id="description"
                            background-color="transparent"
                            outlined
                            dense
                            placeholder="Descrição"
                            v-model="controller.form.description"
                          />
                          <label for="description">Descrição</label>
                        </div>
                      </v-col>
                    </v-row>
                  </v-form>
                  <v-divider></v-divider>
                </v-card-text>
                <v-card-actions class="px-6 pb-3">
                  <v-btn
                    class="action-btn d-flex justify-center"
                    height="36"
                    width="114"
                    color="primary"
                    tile
                    outlined
                    @click="controller.close()"
                  >
                    <v-icon left>mdi-close</v-icon>
                    Cancelar
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    class="action-btn d-flex justify-center"
                    height="36"
                    width="114"
                    color="primary"
                    tile
                    @click="controller.create()"
                    :loading="controller.loading"
                  >
                    <v-icon left>mdi-content-save</v-icon>
                    Salvar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
      </template>
    </v-data-table>
    <v-dialog
      max-width="600"
      persistent
      scrollable
      v-model="controller.dialogUpdateClient"
    >
      <v-card class="crud-card">
        <v-card-title class="d-flex justify-center">Atualizar Cliente</v-card-title>
        <v-card-text>
          <v-form
            ref="crudUpdateClient"
            lazy-validation
          >
            <div
              class="form-input-group"
              v-if="hadPermission(0,0)"
            >
              <v-autocomplete
                class="search-tf"
                single-line
                id="client"
                background-color="transparent"
                outlined
                required
                dense
                placeholder="Cliente"
                v-model="controller.clientIDForUpdate"
                :search-input.sync="controller.searchClient"
                :items="controller.clientPagination.items"
                item-text="name"
                item-value="id"
                :rules="[(v) => !!v || 'Campo obrigatório']"
              />
              <label for="client">Cliente</label>
            </div>
          </v-form>
          <v-row no-gutters>
            <v-col
              cols="12"
              class="d-flex justify-center"
            >
              <h3>Equipamentos para o cliente</h3>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12">
              <v-data-table
                class="astra-table-update"
                dense
                fixed-header
                :headers="controller.updateItensHeaders"
                :items="controller.selectedItems"
                hide-default-footer
                disable-pagination
              >
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="px-6 pb-3">
          <v-btn
            class="action-btn d-flex justify-center"
            height="36"
            width="114"
            color="primary"
            tile
            outlined
            @click="controller.dialogUpdateClient = false"
          >
            <v-icon left>mdi-close</v-icon>
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            class="action-btn d-flex justify-center"
            height="36"
            width="114"
            color="primary"
            tile
            @click="controller.updateClient()"
            :loading="controller.loadingUpdateClient"
          >
            <v-icon left>mdi-reload</v-icon>
            Atualizar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      max-width="600"
      persistent
      scrollable
      v-model="controller.dialogUpdateVehicle"
    >
      <v-card class="crud-card">
        <v-card-title class="d-flex justify-center">Alterar veículo</v-card-title>
        <v-card-text>
          <v-form
            ref="crudUpdateVehicle"
            lazy-validation
          >
            <div class="form-input-group">
              <v-autocomplete
                class="search-tf"
                single-line
                id="vehicle"
                :rules="[(v) => !!v || 'Campo obrigatório']"
                background-color="transparent"
                outlined
                required
                dense
                placeholder="Veículo"
                v-model="controller.form.vehicleID"
                :search-input.sync="controller.searchVehicle"
                :items="controller.vehiclePagination.items"
                item-text="plate"
                item-value="id"
              />
              <label for="vehicle">Veículo</label>
            </div>
          </v-form>
        </v-card-text>
        <v-card-actions class="px-6 pb-3">
          <v-btn
            class="action-btn d-flex justify-center"
            height="36"
            width="114"
            color="primary"
            tile
            outlined
            @click="controller.dialogUpdateVehicle = false"
          >
            <v-icon left>mdi-close</v-icon>
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            class="action-btn d-flex justify-center"
            height="36"
            width="114"
            color="primary"
            tile
            @click="controller.updateVehicle()"
            :loading="controller.loadingUpdateVehicle"
          >
            <v-icon left>mdi-content-save</v-icon>
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      max-width="600"
      persistent
      scrollable
      v-model="controller.dialogGenerateQrCode"
    >
      <v-card class="crud-card">
        <v-card-title class="d-flex justify-center">Gerar senha para o serial {{controller.formCode.serial}}</v-card-title>
        <v-card-text>
          <v-form
            ref="crudGenerateCodeVehicle"
            lazy-validation
          >
            <div class="form-input-group">
              <v-text-field
                class="search-tf"
                single-line
                id="code"
                :rules="[(v) => !!v || 'Campo obrigatório']"
                background-color="transparent"
                outlined
                required
                dense
                placeholder="Rede"
                v-model="controller.formCode.code"
              />
              <label for="code">Rede</label>
            </div>
            <div class="form-input-group">
              <v-select
                class="search-tf"
                single-line
                id="reason"
                :rules="[(v) => !!v || 'Campo obrigatório']"
                background-color="transparent"
                outlined
                required
                dense
                placeholder="Motivo"
                v-model="controller.formCode.descriptionReason"
                :items="controller.reason"
              />
              <label for="reason">Motivo</label>
            </div>
            <div class="form-input-group">
              <v-textarea
                class="search-tf"
                id="obs"
                :rules="[(v) => !!v || 'Campo obrigatório']"
                background-color="transparent"
                outlined
                required
                dense
                placeholder="Observação"
                v-model="controller.formCode.description"
              />
              <label for="obs">Observação</label>
            </div>
          </v-form>
        </v-card-text>

        <v-card-actions class="px-6 pb-3">
          <v-btn
            class="action-btn d-flex justify-center"
            height="36"
            width="114"
            color="primary"
            tile
            outlined
            @click="controller.dialogGenerateQrCode = false"
          >
            <v-icon left>mdi-close</v-icon>
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            class="action-btn d-flex justify-center"
            height="36"
            width="114"
            color="primary"
            tile
            @click="controller.generateCode()"
            :loading="controller.loadingUpdateClient"
          >
            <v-icon left>mdi-content-save</v-icon>
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      max-width="400"
      persistent
      scrollable
      v-model="controller.dialogGenerateQrCodeCreated"
    >
      <v-card
        height="250"
      >
      <v-card-text class="text-center d-flex align-center justify-center fill-height flex-column">
          <div class="display-5 my-10">
            <strong>Senha gerada com sucesso!</strong>
            <br>
            <strong>{{ controller.code.reason }}</strong>
          </div>
          <div
            class="display-2"
            style="color: red"
          >
            <strong>{{ controller.code.password }}</strong>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

 <script>
import { equipmentController } from "@/di/di";
import _ from "lodash";

export default {
  name: "vehicle-page",
  data: (context) => ({
    controller: equipmentController(context),
  }),
  watch: {
    "controller.options"() {
      this.controller.watchOptions();
    },
    "controller.search"() {
      this.controller.watchSearch();
    },
    "controller.modelOptions"() {
      this.controller.watchModelOptions();
    },
    "controller.searchModel": _.debounce(function () {
      this.controller.watchModelSearch();
    }, 500),
    "controller.vehicleOptions"() {
      this.controller.watchVehicleOptions();
    },
    "controller.searchVehicle": _.debounce(function () {
      this.controller.watchVehicleSearch();
    }, 500),
    "controller.equipmentTypeOptions"() {
      this.controller.watchEquipmentTypeOptions();
    },
    "controller.searchEquipmentType": _.debounce(function () {
      this.controller.watchEquipmentTypeSearch();
    }, 500),
    "controller.clientOptions"() {
      this.controller.watchClientOptions();
    },
    "controller.searchClient": _.debounce(function () {
      this.controller.watchClientSearch();
    }, 500),
  },
};
</script> 

<style scoped lang="scss">
.astra-table {
  border-radius: 20px;
  background-color: var(--v-secondary-base) !important;
}

.astra-table-update {
  border: 1px solid var(--v-tertiary-base) !important;
  background-color: var(--v-secondary-base) !important;
}

.toolbar-table {
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
}

::v-deep .v-data-table.v-data-table--fixed-header thead th {
  background-color: var(--v-secondary-base) !important;
}

.action-btn {
  text-transform: none;
  border-radius: 10px !important;
  color: #fff;
}

.search-tf {
  border-radius: 10px !important;
}

.table-title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
}

.crud-card {
  border-radius: 20px;
  background-color: var(--v-secondary-base) !important;
}

::v-deep .v-dialog {
  border-radius: 20px !important;
}

label {
  order: -1;
}

.v-input:focus-within + label {
  color: var(--v-primary-base) !important;
}

.form-input-group {
  display: flex;
  flex-direction: column;
}

.counter-title {
  font-weight: 400;
  font-size: 14px;
}

.counter-chip {
  background-color: var(--v-tertiary-base);
  border-radius: 8px;
  padding: 2px;
  margin-left: 4px;
  font-weight: 400;
  font-size: 14px;
}

::v-deep tbody {
  tr:hover {
    background-color: var(--v-tertiary-base) !important;
  }
}
</style>
