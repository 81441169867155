import { snackbar } from "@/core/controller/snackbar_controller";
import { headers } from "../const/headers";
import { modelDefault } from "../domain/model/model";

class ModelController {
  context = null;
  headers = headers
  loading = false
  search = ""
  dialog = false
  form = modelDefault()

  modelPagination = {
    total: 0,
    items: []
  }

  options = {
    page: 1,
    itemsPerPage: 40,
    groupBy: [],
    sortBy: ["name"],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    sortDesc: []
  }

  constructor(
    context,
    fetchModelUseCaseImpl,
    createModelUseCaseImpl,
    changeModelUseCaseImpl,
    deleteModelUseCaseImpl
  ) {
    this.context = context;
    this.fetchModelUseCaseImpl = fetchModelUseCaseImpl
    this.createModelUseCaseImpl = createModelUseCaseImpl
    this.changeModelUseCaseImpl = changeModelUseCaseImpl
    this.deleteModelUseCaseImpl = deleteModelUseCaseImpl
  }

  async paginate() {
    this.loading = true
    try {
      this.modelPagination = await this.fetchModelUseCaseImpl(this.options, this.search)
    } catch (error) {
      console.log(error);
    } finally {
      this.loading = false
    }
  }

  open() {
    this.form = modelDefault()
  }

  close() {
    this.context.$refs.crud.resetValidation()
    this.dialog = false
  }

  async create() {
    if (this.context.$refs.crud.validate()) {
      try {
        this.loading = true
        if (this.form.id != 0) {
          await this.changeModelUseCaseImpl(this.form.id, this.form)
          snackbar.show({ message: "Alterado com sucesso!!!", color: "#008000", timeout: 3000 })
        } else {
          await this.createModelUseCaseImpl(this.form)
          snackbar.show({ message: "Adicionado com sucesso!!!", color: "#008000", timeout: 3000 })
        }
      } catch (error) {
        snackbar.show({ message: error.toString(), color: "#ff0000", timeout: 3000 })
      } finally {
        this.paginate()
        this.loading = false
        this.dialog = false
      }
    }
  }

  change(item) {
    this.dialog = true
    this.form = { ...item }
  }

  async delete(item) {
    try {
      const confirm = await snackbar.confirm({ message: "Deseja realmente excluir o registro?", color: "primary", timeout: 5000 })
      if (confirm) {
        await this.deleteModelUseCaseImpl(item.id)
        this.paginate()
        snackbar.show({ message: "Resgistro excluído com sucesso!", color: "#008000", timeout: 1000 })
      }
    } catch (error) {
      snackbar.show({ message: error.toString() })
    }
  }


  watchOptions() {
    this.paginate()
  }

  watchSearch() {
    this.paginate()
  }


}

export default ModelController