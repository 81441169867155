class GenerateCode {
  constructor({
    vehicleID = null,
    equipmentID = 0,
    descriptionReason = "",
    description = "",
    code = "", serial = ""
  }) {
    this.vehicleID = vehicleID
    this.equipmentID = equipmentID
    this.descriptionReason = descriptionReason
    this.description = description
    this.code = code
    this.serial = serial
  }
}

export const generateCodeDefault = ({
  vehicleID = null,
  equipmentID = 0,
  descriptionReason = "",
  description = "",
  code = "",
  serial = ""
}) => {
  const generateCode = {
    serial: serial,
    vehicleID: vehicleID,
    equipmentID: equipmentID,
    descriptionReason: descriptionReason,
    description: description,
    code: code,
  }
  return generateCode
}

export default GenerateCode
