import jwtDecode from "jwt-decode";

export function getJwtAuthToken() {
    const auth = localStorage.getItem('auth')
    const jwtAuthToken = JSON.parse(auth ?? '{}')
    return jwtAuthToken
}

export function getAccountGroupID() {
    if (localStorage.getItem('auth') != null) {
        const jwtAuthToken = JSON.parse(localStorage.getItem('auth') ?? '{}')
        const decoded = jwtDecode(jwtAuthToken.token)
        const token = decoded.account

        return token.accountGroupID
    }
}

export function getAccountID() {
    if (localStorage.getItem('auth') != null) {
        const jwtAuthToken = JSON.parse(localStorage.getItem('auth') ?? '{}')
        const decoded = jwtDecode(jwtAuthToken.token)
        const token = decoded.account

        return token.id
    }
}

export function isChangePassword() {
    if (localStorage.getItem('auth') != null) {
        const jwtAuthToken = JSON.parse(localStorage.getItem('auth') ?? '{}')
        const decoded = jwtDecode(jwtAuthToken.token)
        const token = decoded.account

        return token.changePassword
    }
}


export function getAccount() {
    const jwtAuthToken = JSON.parse(localStorage.getItem('auth') ?? '')
    const decoded = jwtDecode(jwtAuthToken.token)
    const token = decoded.account
    return token
}

export function getAccountFromToken(token) {
    const decoded = jwtDecode(token)
    const newToken = decoded.account
    return newToken
}

export function expired(token) {
    try {
        const currentTime = new Date().getTime() / 1000
        const decoded = jwtDecode(token)

        if (currentTime > decoded.exp) {
            return true
        }

        return false
    } catch {
        return true
    }
}

export function getAccountName() {
    const jwtAuthToken = JSON.parse(localStorage.getItem('auth') ?? '')
    const decoded = jwtDecode(jwtAuthToken.token)
    const token = decoded.account

    return token.name
}
