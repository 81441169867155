var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"background",attrs:{"id":"root-login"}},[_c('div',{staticClass:"back-btn"},[(_vm.controller.tab == 1)?_c('v-btn',{staticStyle:{"border-radius":"20px","border":"1px solid green"},attrs:{"color":"primary","height":"40","min-height":"0","width":"40","min-width":"0"},on:{"click":function($event){_vm.controller.tab = 0}}},[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("mdi-chevron-left")])],1):_vm._e()],1),_c('v-stepper',{staticClass:"stepper-login background",attrs:{"elevation":"0"},model:{value:(_vm.controller.tab),callback:function ($$v) {_vm.$set(_vm.controller, "tab", $$v)},expression:"controller.tab"}},[_c('v-stepper-items',[_c('v-stepper-content',{attrs:{"step":"0"}},[_c('v-card',{attrs:{"elevation":"0","width":"500","min-width":"200","color":"background"}},[_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12"}},[_c('v-img',{attrs:{"src":_vm.imageLogin,"max-width":"300","max-height":"400"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mr-2 card-button",attrs:{"width":"200","elevation":"0"},on:{"click":function($event){return _vm.controller.releaseDriver()}}},[_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12"}},[_c('v-icon',{attrs:{"size":"60","color":hover ? 'primary' : ''}},[_vm._v("mdi-truck-fast-outline")])],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12"}},[(hover)?_c('span',{staticClass:"card-title",staticStyle:{"color":"var(--v-primary-base)"}},[_vm._v("LIBERAR VEÍCULO")]):_c('span',{staticClass:"card-title"},[_vm._v("LIBERAR VEÍCULO")])])],1)],1)],1)]}}])}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"ml-2 card-button",attrs:{"width":"200","elevation":"0"},on:{"click":function($event){_vm.controller.tab = 1}}},[_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12"}},[_c('v-icon',{attrs:{"size":"60","color":hover ? 'primary' : ''}},[_vm._v("mdi-laptop")])],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12"}},[(hover)?_c('span',{staticClass:"card-title",staticStyle:{"color":"var(--v-primary-base)"}},[_vm._v("ACESSAR SISTEMA")]):_c('span',{staticClass:"card-title"},[_vm._v("ACESSAR SISTEMA")])])],1)],1)],1)]}}])})],1)],1)],1)],1)],1),_c('v-stepper-content',{attrs:{"step":"1"}},[_c('v-card',{attrs:{"tile":"","width":"500","min-width":"200","color":"background"}},[_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12"}},[_c('v-img',{attrs:{"src":_vm.imageLogin,"max-width":"300","max-height":"400"}})],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-form',{ref:"loginForm"},[_c('div',{staticClass:"pt-4 form-input-group"},[_c('v-text-field',{staticClass:"text-field",attrs:{"id":"email","background-color":"transparent","rules":[_vm.controller.emailRules],"prepend-inner-icon":"mdi-email-outline","outlined":"","required":"","placeholder":"Informe seu e-mail"},model:{value:(_vm.controller.email),callback:function ($$v) {_vm.$set(_vm.controller, "email", $$v)},expression:"controller.email"}}),_c('label',{attrs:{"for":"email"}},[_vm._v("E-mail")])],1),_c('div',{staticClass:"form-input-group"},[_c('v-text-field',{staticClass:"text-field",attrs:{"id":"password","rules":[_vm.controller.requiredRules],"background-color":"transparent","outlined":"","required":"","prepend-inner-icon":"mdi-lock-outline","append-icon":_vm.controller.showPassword
                          ? 'mdi-eye-outline'
                          : 'mdi-eye-off-outline',"type":_vm.controller.showPassword ? 'text' : 'password',"placeholder":"Informe a senha"},on:{"click:append":function($event){_vm.controller.showPassword = !_vm.controller.showPassword}},model:{value:(_vm.controller.password),callback:function ($$v) {_vm.$set(_vm.controller, "password", $$v)},expression:"controller.password"}}),_c('label',{attrs:{"for":"password"}},[_vm._v("Senha")])],1)])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{staticStyle:{"text-transform":"none","height":"45px","z-index":"9999"},attrs:{"loading":_vm.controller.loading,"block":"","color":"primary"},on:{"click":function($event){return _vm.controller.login()}}},[_c('span',[_vm._v("Entrar")])])],1)],1)],1)],1)],1),_c('v-stepper-content',{attrs:{"step":"2"}},[_c('v-card',{attrs:{"tile":"","width":"500","min-width":"200","color":"background"}},[_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12"}},[_c('v-img',{attrs:{"src":_vm.imageLogin,"max-width":"300","max-height":"400"}})],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-form',{ref:"loginForm",staticStyle:{"z-index":"2"}},[_c('div',{staticClass:"form-input-group"},[_c('v-text-field',{staticClass:"text-field",attrs:{"id":"password","rules":[_vm.controller.requiredRules],"background-color":"transparent","outlined":"","required":"","prepend-inner-icon":"mdi-lock-outline","append-icon":_vm.controller.showPassword
                          ? 'mdi-eye-outline'
                          : 'mdi-eye-off-outline',"type":_vm.controller.showPassword ? 'text' : 'password',"placeholder":"Crie uma nova senha"},on:{"click:append":function($event){_vm.controller.showPassword = !_vm.controller.showPassword}},model:{value:(_vm.controller.password),callback:function ($$v) {_vm.$set(_vm.controller, "password", $$v)},expression:"controller.password"}}),_c('label',{attrs:{"for":"password"}},[_vm._v("Nova senha")])],1)])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{staticStyle:{"text-transform":"none","height":"45px","z-index":"9999"},attrs:{"loading":_vm.controller.loading,"block":"","color":"primary"},on:{"click":function($event){return _vm.controller.updatePassword()}}},[_c('span',[_vm._v("Alterar")])])],1)],1)],1)],1)],1)],1)],1),_c('div',{staticClass:"custom-shape-divider-bottom-1656682232",staticStyle:{"z-index":"1"}},[_c('svg',{attrs:{"data-name":"Layer 1","xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 1200 120","preserveAspectRatio":"none"}},[_c('path',{staticClass:"shape-fill",attrs:{"d":"M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"}})])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }