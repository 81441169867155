<template>
  <div>
    <v-data-table
      class="astra-table"
      dense
      height="76vh"
      fixed-header
      :headers="controller.headers"
      :options.sync="controller.options"
      :server-items-length="controller.releasePagination.total"
      :items="controller.releasePagination.items"
      :loading="controller.loading"
      :footer-props="{
        'items-per-page-options': [40, 80],
      }"
    >
      <template v-slot:[`item.createdDate`]="{ item }">
        {{ dayjs(item.createdDate).format("DD/MM/YYYY [às] HH:mm") }}
      </template>

      <template v-slot:top>
        <v-row
          class="pa-3"
          no-gutters
        >
          <v-col
            :class="
              $vuetify.breakpoint.smAndDown
                ? 'py-3 d-flex align-center justify-center'
                : 'd-flex align-center'
            "
            cols="12"
            sm="12"
            md="6"
            lg="6"
            xl="6"
          >
            <span class="table-title">Relatório de geração de senhas</span>
          </v-col>
          <v-col
            class="d-flex align-center justify-end"
            cols="12"
            sm="12"
            md="6"
            lg="6"
            xl="6"
          >
            <v-dialog
              max-width="600"
              v-model="controller.dialog"
              persistent
              scrollable
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="action-btn d-flex justify-center"
                  height="40"
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  tile
                  @click.stop="controller.open()"
                >
                  <v-icon left>mdi-filter</v-icon>
                  Filtro
                </v-btn>
              </template>
              <v-card class="crud-card">
                <v-card-title>
                  <v-spacer></v-spacer>
                  <span class="table-title">Filtro</span>
                  <v-spacer></v-spacer>
                </v-card-title>
                <v-card-text class="pt-3 pb-1">
                  <v-form
                    ref="crud"
                    lazy-validation
                  >
                    <v-row>
                      <v-col cols="12">
                        <div class="form-input-group">
                          <v-autocomplete
                            clearable
                            class="search-tf"
                            single-line
                            id="client"
                            background-color="transparent"
                            outlined
                            required
                            dense
                            placeholder="Cliente"
                            v-model="controller.form.clientID"
                            :search-input.sync="controller.searchClient"
                            :items="controller.clientPagination.items"
                            item-text="name"
                            item-value="id"
                          />
                          <label for="client">Cliente</label>
                        </div>
                        <div class="form-input-group">
                          <v-autocomplete
                            clearable
                            class="search-tf"
                            single-line
                            id="serial"
                            background-color="transparent"
                            outlined
                            required
                            dense
                            placeholder="Serial"
                            v-model="controller.form.equipmentID"
                            :search-input.sync="controller.searchSerial"
                            :items="controller.serialPagination.items"
                            item-text="name"
                            item-value="id"
                          />
                          <label for="serial">Serial</label>
                        </div>
                        <div class="form-input-group">
                          <v-autocomplete
                            clearable
                            class="search-tf"
                            single-line
                            id="plate"
                            background-color="transparent"
                            outlined
                            required
                            dense
                            placeholder="Placa"
                            v-model="controller.form.vehicleID"
                            :search-input.sync="controller.searchPlate"
                            :items="controller.platePagination.items"
                            item-text="plate"
                            item-value="id"
                          />
                          <label for="plate">Placa</label>
                        </div>
                        <div class="form-input-group">
                          <v-autocomplete
                            clearable
                            class="search-tf"
                            single-line
                            id="code"
                            background-color="transparent"
                            outlined
                            required
                            dense
                            placeholder="Rede"
                            v-model="controller.form.codeID"
                            :search-input.sync="controller.searchCode"
                            :items="controller.codePagination.items"
                            item-text="name"
                            item-value="id"
                          />
                          <label for="code">Rede</label>
                        </div>
                        <div class="form-input-group">
                          <v-autocomplete
                            clearable
                            class="search-tf"
                            single-line
                            id="model"
                            background-color="transparent"
                            outlined
                            required
                            dense
                            placeholder="Modelo"
                            v-model="controller.form.modelID"
                            :search-input.sync="controller.searchModel"
                            :items="controller.modelPagination.items"
                            item-text="name"
                            item-value="id"
                          />
                          <label for="model">Modelo</label>
                        </div>
                        <div class="form-input-group">
                          <v-autocomplete
                            clearable
                            class="search-tf"
                            single-line
                            id="account"
                            background-color="transparent"
                            outlined
                            required
                            dense
                            placeholder="Usuário"
                            v-model="controller.form.accountID"
                            :search-input.sync="controller.searchAccount"
                            :items="controller.accountPagination.items"
                            item-text="name"
                            item-value="id"
                          />
                          <label for="account">Usuário</label>
                        </div>
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <div class="form-input-group">
                          <date-picker
                            class="search-tf"
                            single-line
                            id="startDate"
                            background-color="transparent"
                            outlined
                            required
                            dense
                            label="startDate"
                            v-model="controller.form.startDate"
                          ></date-picker>
                          <label for="startDate">Data Inicial</label>
                        </div>
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <div class="form-input-group">
                          <date-picker
                            single-line
                            class="search-tf"
                            id="endDate"
                            background-color="transparent"
                            outlined
                            required
                            dense
                            label="endDate"
                            v-model="controller.form.endDate"
                          ></date-picker>
                          <label for="endDate">Data Final</label>
                        </div>
                      </v-col>
                    </v-row>
                  </v-form>
                  <v-divider></v-divider>
                </v-card-text>
                <v-card-actions class="px-6 pb-3">
                  <v-btn
                    class="action-btn d-flex justify-center"
                    height="36"
                    width="114"
                    color="primary"
                    tile
                    outlined
                    @click="controller.dialog = false"
                  >
                    <v-icon left>mdi-close</v-icon>
                    Cancelar
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    class="action-btn d-flex justify-center"
                    height="36"
                    width="114"
                    color="primary"
                    tile
                    @click="controller.filter()"
                    :loading="controller.loading"
                  >
                    <v-icon left>mdi-filter</v-icon>
                    Filtrar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
      </template>
    </v-data-table>
  </div>
</template>

 <script>
import { releaseController } from "@/di/di";
export default {
  name: "code-page",
  data: (context) => ({
    controller: releaseController(context),
  }),
  watch: {
    "controller.options"() {
      this.controller.watchOptions();
    },
    "controller.serialOptions"() {
      this.controller.paginateEquipment();
    },
    "controller.searchSerial"() {
      this.controller.paginateEquipment();
    },
    "controller.clientOptions"() {
      this.controller.paginateClient();
    },
    "controller.searchClient"() {
      this.controller.paginateClient();
    },
    "controller.plateOptions"() {
      this.controller.paginateVehicle();
    },
    "controller.searchPlate"() {
      this.controller.paginateVehicle();
    },
    "controller.codeOptions"() {
      this.controller.paginateCode();
    },
    "controller.searchCode"() {
      this.controller.paginateCode();
    },
    "controller.modelOptions"() {
      this.controller.paginateModel();
    },
    "controller.searchModel"() {
      this.controller.paginateModel();
    },
    "controller.accountOptions"() {
      this.controller.paginateAccount();
    },
    "controller.searchAccount"() {
      this.controller.paginateAccount();
    },
  },
};
</script> 

<style scoped lang="scss">
.astra-table {
  border-radius: 20px;
  background-color: var(--v-secondary-base) !important;
}

.toolbar-table {
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
}

::v-deep .v-data-table.v-data-table--fixed-header thead th {
  background-color: var(--v-secondary-base) !important;
}

.action-btn {
  text-transform: none;
  border-radius: 10px !important;
}

.search-tf {
  border-radius: 10px !important;
}

.table-title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
}

.crud-card {
  border-radius: 20px;
  background-color: var(--v-secondary-base) !important;
}

::v-deep .v-dialog {
  border-radius: 20px !important;
}

label {
  order: -1;
}

.v-input:focus-within + label {
  color: var(--v-primary-base) !important;
}

.form-input-group {
  display: flex;
  flex-direction: column;
}

::v-deep tbody {
  tr:hover {
    background-color: var(--v-tertiary-base) !important;
  }
}
</style>
