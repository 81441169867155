import { snackbar } from "@/core/controller/snackbar_controller";
import { headers } from "../const/headers";
import { clientDefault } from "../domain/client/client";

class ClientController {
  context = null;
  headers = headers
  loading = false
  search = ""
  searchCity = ""
  dialog = false
  form = clientDefault()
  statusSelected = 0
  statusList = [
    { text: "Todos", value: 0 },
    { text: "Ativo", value: 1 },
    { text: "Inativo", value: 2 },
  ]

  clientPagination = {
    total: 0,
    items: []
  }

  options = {
    page: 1,
    itemsPerPage: 40,
    groupBy: [],
    sortBy: ["name"],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    sortDesc: []
  }

  cityPagination = {
    total: 0,
    items: []
  }

  cityOptions = {
    page: 1,
    itemsPerPage: 40,
    groupBy: [],
    sortBy: ["name"],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    sortDesc: []
  }

  constructor(
    context,
    fetchClientUseCaseImpl,
    createClientUseCaseImpl,
    changeClientUseCaseImpl,
    deleteClientUseCaseImpl,
    fetchCityUseCaseImpl
  ) {
    this.context = context;
    this.fetchClientUseCaseImpl = fetchClientUseCaseImpl
    this.createClientUseCaseImpl = createClientUseCaseImpl
    this.changeClientUseCaseImpl = changeClientUseCaseImpl
    this.deleteClientUseCaseImpl = deleteClientUseCaseImpl
    this.fetchCityUseCaseImpl = fetchCityUseCaseImpl
  }

  async paginate() {
    this.loading = true
    try {
      this.clientPagination = await this.fetchClientUseCaseImpl(this.options, this.search, this.statusSelected)
    } catch (error) {
      console.log(error);
    } finally {
      this.loading = false
    }
  }

  async cityPaginate() {
    try {
      this.cityPagination = await this.fetchCityUseCaseImpl(this.cityOptions, this.searchCity)
    } catch (error) {
      console.log(error);
    }
  }

  open() {
    this.form = clientDefault()
  }

  close() {
    this.context.$refs.crud.resetValidation()
    this.dialog = false
  }

  async create() {
    if (this.context.$refs.crud.validate()) {
      try {
        this.loading = true
        if (this.form.id != 0) {
          await this.changeClientUseCaseImpl(this.form.id, this.form)
          snackbar.show({ message: "Alterado com sucesso!!!", color: "#008000", timeout: 3000 })
        } else {
          await this.createClientUseCaseImpl(this.form)
          snackbar.show({ message: "Adicionado com sucesso!!!", color: "#008000", timeout: 3000 })
        }
      } catch (error) {
        snackbar.show({ message: error.toString(), color: "#ff0000", timeout: 3000 })
      } finally {
        this.paginate()
        this.loading = false
        this.dialog = false
      }
    }
  }

  change(item) {
    this.dialog = true
    this.form = { ...item }
    this.searchCity = item.cityName
    this.cityPaginate()
  }

  async delete(item) {
    try {
      const confirm = await snackbar.confirm({ message: "Deseja realmente excluir o registro?", color: "primary", timeout: 5000 })
      if (confirm) {
        await this.deleteClientUseCaseImpl(item.id)
        this.paginate()
        snackbar.show({ message: "Resgistro excluído com sucesso!", color: "#008000", timeout: 1000 })
      }
    } catch (error) {
      snackbar.show({ message: error.toString() })
    }
  }


  watchOptions() {
    this.paginate()
  }

  watchSearch() {
    this.paginate()
  }
  watchCityOptions() {
    this.cityPaginate()
  }

  watchCitySearch() {
    this.cityPaginate()
  }

  changeStatus() {
    this.paginate()
  }


}

export default ClientController