
const changeClientUseCase = (repository) => async (id, data) => {
    if (!data.associate) {
        data.nameAssociation = null
    }
    return await repository(id, data);
}

export default changeClientUseCase;

