<template>
  <div>
    <v-row class="py-2" no-gutters>
      <v-col class="d-flex justify-end align-center" cols="12">
        <div class="d-flex align-center justify-center user-avatar">
          {{ avatar }}
        </div>
        <span class="pl-4 user-name">{{ controller.userName }}</span>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on"
              ><v-icon size="30">mdi-menu-down</v-icon></v-btn
            >
          </template>
          <v-card color="secondary">
            <v-card-text>
              <v-switch
                inset
                hide-details
                v-model="controller.dark"
                color="primary"
                :label="controller.dark ? 'Modo escuro' : 'Modo claro'"
              ></v-switch>
            </v-card-text>
          </v-card>
        </v-menu>
      </v-col>
    </v-row>
    <v-main class="main-home">
      <component :is="controller.selected"></component>
    </v-main>

    <div class="sidebar px-2">
      <v-row no-gutters class="pt-4 pb-2">
        <v-col cols="12" class="d-flex align-center justify-center">
          <div>
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  @click="controller.navigate(0)"
                  v-bind="attrs"
                  v-on="on"
                  size="28"
                  color="primary"
                  >mdi-square-rounded-outline</v-icon
                >
              </template>
              <span>Dashboard</span>
            </v-tooltip>
          </div>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row no-gutters class="pt-4" v-if="hadPermission(0,0)">
        <v-col cols="12" class="d-flex align-center justify-center">
          <div>
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  elevation="0"
                  max-height="40"
                  min-height="0"
                  min-width="0"
                  max-width="40"
                  color="secondary"
                  v-bind="attrs"
                  v-on="on"
                  class="menu-btn"
                  @click="controller.navigate(2)"
                >
                  <v-icon size="24" color="primary">mdi-barcode-scan</v-icon>
                </v-btn>
              </template>
              <span>Redes</span>
            </v-tooltip>
          </div>
        </v-col>
      </v-row>
      <v-row no-gutters class="pt-4" v-if="hadPermission(0,0)">
        <v-col cols="12" class="d-flex align-center justify-center">
          <div>
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  elevation="0"
                  max-height="40"
                  min-height="0"
                  min-width="0"
                  max-width="40"
                  color="secondary"
                  v-bind="attrs"
                  v-on="on"
                  class="menu-btn"
                  @click="controller.navigate(3)"
                >
                  <v-icon size="24" color="primary">mdi-account-group</v-icon>
                </v-btn>
              </template>
              <span>Clientes</span>
            </v-tooltip>
          </div>
        </v-col>
      </v-row>
      <v-row no-gutters class="pt-4" v-if="hadPermission(3,4)">
        <v-col cols="12" class="d-flex align-center justify-center">
          <div>
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  elevation="0"
                  max-height="40"
                  min-height="0"
                  min-width="0"
                  max-width="40"
                  color="secondary"
                  v-bind="attrs"
                  v-on="on"
                  class="menu-btn"
                  @click="controller.navigate(1)"
                >
                  <v-icon size="24" color="primary">mdi-inbox</v-icon>
                </v-btn>
              </template>
              <span>Modelos</span>
            </v-tooltip>
          </div>
        </v-col>
      </v-row>
      <v-row no-gutters class="pt-4" v-if="hadPermission(4,4)">
        <v-col cols="12" class="d-flex align-center justify-center">
          <div>
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  elevation="0"
                  max-height="40"
                  min-height="0"
                  min-width="0"
                  max-width="40"
                  color="secondary"
                  v-bind="attrs"
                  v-on="on"
                  class="menu-btn"
                  @click="controller.navigate(4)"
                >
                  <v-icon size="24" color="primary">mdi-truck</v-icon>
                </v-btn>
              </template>
              <span>Veículos</span>
            </v-tooltip>
          </div>
        </v-col>
      </v-row>
      <v-row no-gutters class="pt-4" v-if="hadPermission(2,4)">
        <v-col cols="12" class="d-flex align-center justify-center">
          <div>
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  elevation="0"
                  max-height="40"
                  min-height="0"
                  min-width="0"
                  max-width="40"
                  color="secondary"
                  v-bind="attrs"
                  v-on="on"
                  class="menu-btn"
                  @click="controller.navigate(5)"
                >
                  <v-icon size="24" color="primary">mdi-radio-tower</v-icon>
                </v-btn>
              </template>
              <span>Equipamentos</span>
            </v-tooltip>
          </div>
        </v-col>
      </v-row>
      <v-row no-gutters class="pt-4 pb-4" v-if="hadPermission(7,4)">
        <v-col cols="12" class="d-flex align-center justify-center">
          <div>
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  elevation="0"
                  max-height="40"
                  min-height="0"
                  min-width="0"
                  max-width="40"
                  color="secondary"
                  v-bind="attrs"
                  v-on="on"
                  class="menu-btn"
                  @click="controller.navigate(6)"
                >
                  <v-icon size="24" color="primary">mdi-account</v-icon>
                </v-btn>
              </template>
              <span>Usuários</span>
            </v-tooltip>
          </div>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row no-gutters class="pt-4 pb-4" v-if="hadPermission(6,4)">
        <v-col cols="12" class="d-flex align-center justify-center">
          <div>
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  elevation="0"
                  max-height="40"
                  min-height="0"
                  min-width="0"
                  max-width="40"
                  color="secondary"
                  v-bind="attrs"
                  v-on="on"
                  class="menu-btn"
                  @click="controller.navigate(7)"
                >
                  <v-icon size="24" color="primary">mdi-boom-gate-up</v-icon>
                </v-btn>
              </template>
              <span>Liberações</span>
            </v-tooltip>
          </div>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12" class="d-flex align-center justify-center">
          <div class="logout-btn">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  elevation="0"
                  max-height="40"
                  min-height="0"
                  min-width="0"
                  max-width="40"
                  color="secondary"
                  v-bind="attrs"
                  v-on="on"
                  class="menu-btn"
                  @click="controller.logout()"
                >
                  <v-icon size="24" color="primary">mdi-logout-variant</v-icon>
                </v-btn>
              </template>
              <span>Sair</span>
            </v-tooltip>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { homeController } from "@/di/di";

export default {
  data: (context) => ({
    controller: homeController(context),
  }),
  async mounted() {
    this.controller.mounted();
  },
  watch: {
    "controller.dark"() {
      this.$vuetify.theme.dark = this.controller.dark;
      localStorage.setItem("dark", this.controller.dark);
    },
  },
  computed: {
    avatar() {
      const avatar = this.controller.userName.split(" ");
      return avatar.length > 1
        ? avatar[0].substr(0, 1) + avatar[1].substr(0, 1)
        : avatar[0].substr(0, 1);
    },
  },
};
</script>

<style scoped>
.sidebar {
  z-index: 100;
  position: absolute;
  top: 10px;
  left: 10px;
  /*  background-color: transparent; */
  background-color: var(--v-secondary-base);
  width: 60px;
  height: 96vh;
  border-radius: 20px;
  overflow: auto;
  top: 50%;
  transform: translateY(-50%);
}
.menu-btn:hover {
  border: 1px solid var(--v-primary-base) !important;
}
.logout-btn {
  position: absolute;
  bottom: 20px;
}
.user-name {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 12px;
  letter-spacing: 0.4px;
  padding-left: 4px;
  display: flex;
  align-items: center;
  justify-items: center;
}
.user-avatar {
  width: 60px;
  height: 60px;
  border-radius: 20px;
  background-color: var(--v-secondary-base);
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 12px;
  letter-spacing: 0.4px;
  padding-left: 4px;
}
.main-home {
  margin-left: 84px;
  margin-right: 10px;
}
</style>
