class Model {
  constructor(
    descriptionUse,
    firmwareVersion,
    id,
    name
  ) {
    this.descriptionUse = descriptionUse
    this.firmwareVersion = firmwareVersion
    this.id = id
    this.name = name
  }

}

export const modelDefault = () => {
  const model = {
    id: 0,
    name: "",
    firmwareVersion: "",
    descriptionUse: "",
  }
  return model
}

export default Model