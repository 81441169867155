const fetchCodeUseCase = (repository) => async (pagination, search) => {
    const payload = {
        sort: pagination.sortBy.map((s) => [s]).join(','),
        descending: pagination.sortDesc.join(','),
        page: pagination.page,
        itemsPerPage: pagination.itemsPerPage,
        search: search,
    }
    return await repository(payload);
}

export default fetchCodeUseCase;