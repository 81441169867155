var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"py-2",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex justify-end align-center",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex align-center justify-center user-avatar"},[_vm._v(" "+_vm._s(_vm.avatar)+" ")]),_c('span',{staticClass:"pl-4 user-name"},[_vm._v(_vm._s(_vm.controller.userName))]),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"30"}},[_vm._v("mdi-menu-down")])],1)]}}])},[_c('v-card',{attrs:{"color":"secondary"}},[_c('v-card-text',[_c('v-switch',{attrs:{"inset":"","hide-details":"","color":"primary","label":_vm.controller.dark ? 'Modo escuro' : 'Modo claro'},model:{value:(_vm.controller.dark),callback:function ($$v) {_vm.$set(_vm.controller, "dark", $$v)},expression:"controller.dark"}})],1)],1)],1)],1)],1),_c('v-main',{staticClass:"main-home"},[_c(_vm.controller.selected,{tag:"component"})],1),_c('div',{staticClass:"sidebar px-2"},[_c('v-row',{staticClass:"pt-4 pb-2",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex align-center justify-center",attrs:{"cols":"12"}},[_c('div',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"28","color":"primary"},on:{"click":function($event){return _vm.controller.navigate(0)}}},'v-icon',attrs,false),on),[_vm._v("mdi-square-rounded-outline")])]}}])},[_c('span',[_vm._v("Dashboard")])])],1)])],1),_c('v-divider'),(_vm.hadPermission(0,0))?_c('v-row',{staticClass:"pt-4",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex align-center justify-center",attrs:{"cols":"12"}},[_c('div',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"menu-btn",attrs:{"elevation":"0","max-height":"40","min-height":"0","min-width":"0","max-width":"40","color":"secondary"},on:{"click":function($event){return _vm.controller.navigate(2)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"24","color":"primary"}},[_vm._v("mdi-barcode-scan")])],1)]}}],null,false,2906038220)},[_c('span',[_vm._v("Redes")])])],1)])],1):_vm._e(),(_vm.hadPermission(0,0))?_c('v-row',{staticClass:"pt-4",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex align-center justify-center",attrs:{"cols":"12"}},[_c('div',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"menu-btn",attrs:{"elevation":"0","max-height":"40","min-height":"0","min-width":"0","max-width":"40","color":"secondary"},on:{"click":function($event){return _vm.controller.navigate(3)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"24","color":"primary"}},[_vm._v("mdi-account-group")])],1)]}}],null,false,116328080)},[_c('span',[_vm._v("Clientes")])])],1)])],1):_vm._e(),(_vm.hadPermission(3,4))?_c('v-row',{staticClass:"pt-4",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex align-center justify-center",attrs:{"cols":"12"}},[_c('div',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"menu-btn",attrs:{"elevation":"0","max-height":"40","min-height":"0","min-width":"0","max-width":"40","color":"secondary"},on:{"click":function($event){return _vm.controller.navigate(1)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"24","color":"primary"}},[_vm._v("mdi-inbox")])],1)]}}],null,false,4075538355)},[_c('span',[_vm._v("Modelos")])])],1)])],1):_vm._e(),(_vm.hadPermission(4,4))?_c('v-row',{staticClass:"pt-4",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex align-center justify-center",attrs:{"cols":"12"}},[_c('div',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"menu-btn",attrs:{"elevation":"0","max-height":"40","min-height":"0","min-width":"0","max-width":"40","color":"secondary"},on:{"click":function($event){return _vm.controller.navigate(4)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"24","color":"primary"}},[_vm._v("mdi-truck")])],1)]}}],null,false,252941791)},[_c('span',[_vm._v("Veículos")])])],1)])],1):_vm._e(),(_vm.hadPermission(2,4))?_c('v-row',{staticClass:"pt-4",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex align-center justify-center",attrs:{"cols":"12"}},[_c('div',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"menu-btn",attrs:{"elevation":"0","max-height":"40","min-height":"0","min-width":"0","max-width":"40","color":"secondary"},on:{"click":function($event){return _vm.controller.navigate(5)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"24","color":"primary"}},[_vm._v("mdi-radio-tower")])],1)]}}],null,false,101524706)},[_c('span',[_vm._v("Equipamentos")])])],1)])],1):_vm._e(),(_vm.hadPermission(7,4))?_c('v-row',{staticClass:"pt-4 pb-4",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex align-center justify-center",attrs:{"cols":"12"}},[_c('div',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"menu-btn",attrs:{"elevation":"0","max-height":"40","min-height":"0","min-width":"0","max-width":"40","color":"secondary"},on:{"click":function($event){return _vm.controller.navigate(6)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"24","color":"primary"}},[_vm._v("mdi-account")])],1)]}}],null,false,3684979879)},[_c('span',[_vm._v("Usuários")])])],1)])],1):_vm._e(),_c('v-divider'),(_vm.hadPermission(6,4))?_c('v-row',{staticClass:"pt-4 pb-4",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex align-center justify-center",attrs:{"cols":"12"}},[_c('div',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"menu-btn",attrs:{"elevation":"0","max-height":"40","min-height":"0","min-width":"0","max-width":"40","color":"secondary"},on:{"click":function($event){return _vm.controller.navigate(7)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"24","color":"primary"}},[_vm._v("mdi-boom-gate-up")])],1)]}}],null,false,1099254586)},[_c('span',[_vm._v("Liberações")])])],1)])],1):_vm._e(),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex align-center justify-center",attrs:{"cols":"12"}},[_c('div',{staticClass:"logout-btn"},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"menu-btn",attrs:{"elevation":"0","max-height":"40","min-height":"0","min-width":"0","max-width":"40","color":"secondary"},on:{"click":function($event){return _vm.controller.logout()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"24","color":"primary"}},[_vm._v("mdi-logout-variant")])],1)]}}])},[_c('span',[_vm._v("Sair")])])],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }