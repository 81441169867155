class Code {
  constructor(
    id,
    name,
    password,
  ) {
    this.id = id
    this.name = name
    this.password = password
  }

}

export const codeDefault = () => {
  const code = {
    id: 0,
    name: "",
    password: "",
  }
  return code
}

export default Code