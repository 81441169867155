import { snackbar } from "@/core/controller/snackbar_controller";
import { headers } from "../const/headers";
import { equipmentDefault } from "../domain/equipment/equipment";
import * as XLSX from "xlsx";
import QRCodeReport from "@/helpers/qrCodeReport";

class EquipmentController {
  context = null;
  headers = headers
  updateItensHeaders = [{
    text: 'Serial',
    align: 'start',
    sortable: true,
    value: 'name',
    divider: true
  },
  {
    text: 'Modelo',
    align: 'start',
    sortable: true,
    value: 'model',
    divider: true
  }]
  loading = false
  loadingBtnExcel = false
  search = ""
  searchModel = ""
  searchVehicle = ""
  searchEquipmentType = ""
  searchClient = ""
  dialog = false
  dialogUpdateClient = false
  dialogUpdateVehicle = false
  dialogGenerateQrCode = false
  clientIDForUpdate = null
  loadingUpdateClient = false
  loadingUpdateVehicle = false
  dialogGenerateQrCodeCreated = false
  form = equipmentDefault()
  formCode = {}
  statusSelected = 0
  statusList = [
    { text: "Todos", value: 0 },
    { text: "Ativo", value: 1 },
    { text: "Inativo", value: 2 },
  ]
  reason = ["Instalação nova", "Manutenção no veículo", "Bloqueado"]
  totalEquipmentsPlate = {}

  selectedItems = []
  equipmentPagination = {
    total: 0,
    items: []
  }

  options = {
    page: 1,
    itemsPerPage: 40,
    groupBy: [],
    sortBy: ["id"],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    sortDesc: []
  }

  modelPagination = {
    total: 0,
    items: []
  }

  modelOptions = {
    page: 1,
    itemsPerPage: 100,
    groupBy: [],
    sortBy: ["name"],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    sortDesc: []
  }


  vehiclePagination = {
    total: 0,
    items: []
  }

  vehicleOptions = {
    page: 1,
    itemsPerPage: 40,
    groupBy: [],
    sortBy: ["plate"],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    sortDesc: []
  }


  equipmentTypePagination = {
    total: 0,
    items: []
  }

  equipmentTypeOptions = {
    page: 1,
    itemsPerPage: 40,
    groupBy: [],
    sortBy: ["id"],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    sortDesc: []
  }

  clientPagination = {
    total: 0,
    items: []
  }

  clientOptions = {
    page: 1,
    itemsPerPage: 40,
    groupBy: [],
    sortBy: ["name"],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    sortDesc: []
  }

  code = {}

  constructor(
    context,
    fetchEquipmentUseCaseImpl,
    createEquipmentUseCaseImpl,
    changeEquipmentUseCaseImpl,
    deleteEquipmentUseCaseImpl,
    fetchModelUseCaseImpl,
    fetchVehicleUseCaseImpl,
    fetchEquipmentTypeUseCaseImpl,
    countEquipmentPlateUseCaseImpl,
    fetchClientUseCaseImpl,
    createReleaseUseCaseImpl,
    refreshUseCaseImpl
  ) {
    this.context = context;
    this.fetchEquipmentUseCaseImpl = fetchEquipmentUseCaseImpl
    this.createEquipmentUseCaseImpl = createEquipmentUseCaseImpl
    this.changeEquipmentUseCaseImpl = changeEquipmentUseCaseImpl
    this.deleteEquipmentUseCaseImpl = deleteEquipmentUseCaseImpl
    this.fetchModelUseCaseImpl = fetchModelUseCaseImpl
    this.fetchVehicleUseCaseImpl = fetchVehicleUseCaseImpl
    this.fetchEquipmentTypeUseCaseImpl = fetchEquipmentTypeUseCaseImpl
    this.countEquipmentPlateUseCaseImpl = countEquipmentPlateUseCaseImpl
    this.fetchClientUseCaseImpl = fetchClientUseCaseImpl
    this.createReleaseUseCaseImpl = createReleaseUseCaseImpl
    this.refreshUseCaseImpl = refreshUseCaseImpl
  }


  async paginate() {
    this.loading = true
    try {
      this.equipmentPagination = await this.fetchEquipmentUseCaseImpl(this.options, this.search, this.statusSelected)
      this.totalEquipmentsPlate = await this.countEquipmentPlateUseCaseImpl()
    } catch (error) {
      console.log(error);
    } finally {
      this.loading = false
    }
  }
  async modelPaginate() {
    try {
      this.modelPagination = await this.fetchModelUseCaseImpl(this.modelOptions, this.searchModel)
    } catch (error) {
      console.log(error);
    }
  }
  async vehiclePaginate() {
    try {
      this.vehiclePagination = await this.fetchVehicleUseCaseImpl(this.vehicleOptions, this.searchVehicle)
    } catch (error) {
      console.log(error);
    }
  }
  async equipmentTypePaginate() {
    try {
      this.equipmentTypePagination = await this.fetchEquipmentTypeUseCaseImpl(this.equipmentTypeOptions, this.searchEquipmentType)
    } catch (error) {
      console.log(error);
    }
  }
  async clientPaginate() {
    try {
      this.clientPagination = await this.fetchClientUseCaseImpl(this.clientOptions, this.searchClient)
    } catch (error) {
      console.log(error);
    }
  }


  async open() {
    setTimeout(() => {
      this.context.$refs.crud.resetValidation()
    }, 200)
    await this.equipmentTypePaginate()
    this.form = equipmentDefault()
  }

  close() {
    this.context.$refs.crud.resetValidation()
    this.dialog = false
  }

  async create() {
    if (this.context.$refs.crud.validate()) {
      try {
        this.loading = true
        if (this.form.id != 0) {
          await this.changeEquipmentUseCaseImpl(this.form.id, this.form)
          snackbar.show({ message: "Alterado com sucesso!!!", color: "#008000", timeout: 3000 })
        } else {
          await this.createEquipmentUseCaseImpl(this.form)
          snackbar.show({ message: "Adicionado com sucesso!!!", color: "#008000", timeout: 3000 })
        }

        await this.refreshUseCaseImpl()
      } catch (error) {
        snackbar.show({ message: error.toString(), color: "#ff0000", timeout: 3000 })
      } finally {
        this.paginate()
        this.loading = false
        this.dialog = false
      }
    }
  }

  async change(item) {
    await this.equipmentTypePaginate()
    this.dialog = true
    this.form = { ...item }
    this.searchVehicle = item.plate
    this.vehiclePaginate()
  }

  async delete(item) {
    try {
      const confirm = await snackbar.confirm({ message: "Deseja realmente excluir o registro?", color: "primary", timeout: 5000 })
      if (confirm) {
        await this.deleteEquipmentUseCaseImpl(item.id)
        this.paginate()
        snackbar.show({ message: "Resgistro excluído com sucesso!", color: "#008000", timeout: 1000 })
      }
    } catch (error) {
      snackbar.show({ message: error.toString() })
    }
  }


  watchOptions() {
    this.paginate()
  }
  watchSearch() {
    this.paginate()
  }
  watchModelOptions() {
    this.modelPaginate()
  }
  watchModelSearch() {
    this.modelPaginate()
  }
  watchVehicleOptions() {
    this.vehiclePaginate()
  }
  watchVehicleSearch() {
    this.vehiclePaginate()
  }
  watchEquipmentTypeOptions() {
    this.equipmentTypePaginate()
  }
  watchEquipmentTypeSearch() {
    this.equipmentTypePaginate()
  }
  watchClientOptions() {
    this.clientPaginate()
  }
  watchClientSearch() {
    this.clientPaginate()
  }

  async exportExcel() {
    try {
      const heading = [['ID', 'NOME', 'ID MODELO', 'DATA CRIACAO', 'ID USUARIO', 'ID VEICULO', 'ATIVO', 'ID TIPO EQUIPAMENTO', 'MAC', 'ID CLIENTE', 'DATA ALTERACAO', 'MODELO', 'USUARIO', 'TIPO', 'NOME CLIENTE']];
      this.loadingBtnExcel = true
      const paginate = { ...this.options }
      paginate.itemsPerPage = 30000
      const data = await this.fetchEquipmentUseCaseImpl(paginate, "")

      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet([]);
      XLSX.utils.sheet_add_aoa(ws, heading);
      XLSX.utils.sheet_add_json(ws, data.items.map((item) => {
        const i = JSON.parse(JSON.stringify(item))
        return i
      }), { origin: 'A2', skipHeader: true })
      XLSX.utils.book_append_sheet(wb, ws, 'equip');

      XLSX.writeFile(wb, `equipamentos.xlsx`);
    } catch (error) {
      snackbar.show({ message: error.toString() })
    } finally {
      this.loadingBtnExcel = false
    }
  }

  changeStatus() {
    this.paginate()
  }

  async updateClient() {
    if (this.context.$refs.crudUpdateClient.validate() && this.clientIDForUpdate != null) {
      try {
        this.loadingUpdateClient = true

        for (const equipment of this.selectedItems) {
          equipment.clientID = this.clientIDForUpdate
          await this.changeEquipmentUseCaseImpl(equipment.id, equipment)

        }

        snackbar.show({ message: "Alterado com sucesso!!!", color: "#008000", timeout: 3000 })
        this.clientIDForUpdate = null
        this.dialogUpdateClient = false
        this.context.$refs.crudUpdateClient.resetValidation()
        this.paginate()
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingUpdateClient = false
      }
    }
  }

  async updateVehicleOpen(data) {
    this.dialogUpdateVehicle = true
    this.form = { ...data }
    this.searchVehicle = data.plate
    this.vehiclePaginate()
  }

  generateQrCodeOpen(data) {
    this.dialogGenerateQrCode = true
    this.formCode = {
      serial: data.name,
      code: '',
      description: '',
      descriptionReason: '',
      equipmentID: data.id,
      vehicleID: data.vehicleID
    }
  }

  async updateVehicle() {
    if (this.context.$refs.crudUpdateVehicle.validate()) {
      try {
        this.loadingUpdateVehicle = true
        await this.changeEquipmentUseCaseImpl(this.form.id, this.form)
        snackbar.show({ message: "Alterado com sucesso!!!", color: "#008000", timeout: 3000 })
        this.dialogUpdateVehicle = false
        this.paginate()
      } catch (error) {
        snackbar.show({ message: error.toString(), color: "#ff0000", timeout: 3000 })
      } finally {
        this.loadingUpdateVehicle = false
      }
    }
  }

  qrCode() {
    const report = new QRCodeReport()
    report.call([...this.selectedItems])
  }

  async generateCode() {
    if (this.context.$refs.crudGenerateCodeVehicle.validate()) {
      try {
        this.loading = true
        this.code = await this.createReleaseUseCaseImpl(this.formCode)
        this.dialogGenerateQrCodeCreated = true

        setTimeout(() => {
          this.dialogGenerateQrCodeCreated = false
          this.code = {}
        }, 15000)
      } catch (error) {
        snackbar.show({ message: error, color: "#ff0000", timeout: 3000 })
      } finally {
        this.paginate()
        this.loading = false
        this.dialogGenerateQrCode = false
      }
    }
  }

}

export default EquipmentController