import Handler from '@/core/domain/usecase/handler';

const loginRepostory = (axios) => async (username, password) => {
    try {
        const response = await axios.post('/session', {
            "email": username,
            "password": password
        })
        const jwtAuthToken = response.data
        return jwtAuthToken
    } catch (err) {
        if (err.isAxiosError) {
            throw new Handler().trataErro(err)
        } else {
            throw err
        }
    }
}

export default loginRepostory