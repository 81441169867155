import { snackbar } from "@/core/controller/snackbar_controller";
import { headers } from "../const/headers";
import { vehicleDefault } from "../domain/vehicle/vehicle";
import * as XLSX from "xlsx";
import { generateCodeDefault } from "../domain/vehicle/generateCode";

class VehicleController {
  context = null;
  headers = headers
  loading = false
  loadingBtnExcel = false
  search = ""
  searchClient = ""
  searchEquipment = ""
  dialog = false
  changeDialog = false
  dialogGenerateQrCode = false
  form = vehicleDefault()
  formCode = generateCodeDefault({})
  statusSelected = 0
  statusList = [
    { text: "Todos", value: 0 },
    { text: "Ativo", value: 1 },
    { text: "Inativo", value: 2 },
  ]

  code = {}

  equipments = []

  dialogGenerateQrCodeCreated = false

  reason = ["Instalação nova", "Manutenção no veículo", "Bloqueado"]

  vehiclePagination = {
    total: 0,
    items: []
  }

  options = {
    page: 1,
    itemsPerPage: 40,
    groupBy: [],
    sortBy: ["id"],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    sortDesc: []
  }

  clientPagination = {
    total: 0,
    items: []
  }

  clientOptions = {
    page: 1,
    itemsPerPage: 40,
    groupBy: [],
    sortBy: ["name"],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    sortDesc: []
  }

  equipmentPagination = {
    total: 0,
    items: []
  }

  equipmentOptions = {
    page: 1,
    itemsPerPage: 40,
    groupBy: [],
    sortBy: ["name"],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    sortDesc: []
  }

  constructor(
    context,
    fetchVehicleUseCaseImpl,
    createVehicleUseCaseImpl,
    changeVehicleUseCaseImpl,
    deleteVehicleUseCaseImpl,
    fetchClientUseCaseImpl,
    fetchEquipmentUseCaseImpl,
    refreshUseCaseImpl,
    createReleaseUseCaseImpl
  ) {
    this.context = context;
    this.fetchVehicleUseCaseImpl = fetchVehicleUseCaseImpl
    this.createVehicleUseCaseImpl = createVehicleUseCaseImpl
    this.changeVehicleUseCaseImpl = changeVehicleUseCaseImpl
    this.deleteVehicleUseCaseImpl = deleteVehicleUseCaseImpl
    this.fetchClientUseCaseImpl = fetchClientUseCaseImpl
    this.fetchEquipmentUseCaseImpl = fetchEquipmentUseCaseImpl
    this.refreshUseCaseImpl = refreshUseCaseImpl
    this.createReleaseUseCaseImpl = createReleaseUseCaseImpl
  }

  async paginate() {
    this.loading = true
    try {
      this.vehiclePagination = await this.fetchVehicleUseCaseImpl(this.options, this.search, this.statusSelected)
    } catch (error) {
      console.log(error);
    } finally {
      this.loading = false
    }
  }

  async clientPaginate() {
    try {
      this.clientPagination = await this.fetchClientUseCaseImpl(this.clientOptions, this.searchClient)
    } catch (error) {
      console.log(error);
    }
  }

  async equipmentPaginate() {
    try {
      this.equipmentPagination = await this.fetchEquipmentUseCaseImpl(this.equipmentOptions, this.searchEquipment)
    } catch (error) {
      console.log(error);
    }
  }

  open() {
    this.form = vehicleDefault()
  }

  openCodeGenerator(item) {
    this.equipments = item.equipments;
    if (item.equipments.length > 1) {
      for (const equipment of this.equipments) {
        if (equipment.mac == null) {
          equipment.mac = "Jammer Principal (sem mac)";
        }
      }

      const firstEquipment = this.equipments.filter((item) => item.mac == "Jammer Principal (sem mac)")[0]

      this.formCode = generateCodeDefault({ vehicleID: item.id, equipmentID: firstEquipment, serial: firstEquipment.name })
      this.dialogGenerateQrCode = true
      return
    }

    if (item.equipments.length == 1) {
      this.formCode = generateCodeDefault({ vehicleID: item.id, equipmentID: item.equipments[0], serial: item.equipments[0].name })
      this.dialogGenerateQrCode = true
      return
    }

    snackbar.show({ message: "Veículo sem equipamentos cadastrados!", color: "#ff0000", timeout: 3000 })
  }

  close() {
    this.context.$refs.crud.resetValidation()
    this.dialog = false
  }

  async create() {
    if (this.context.$refs.crud.validate()) {
      try {
        this.loading = true
        /*         const equipmentIds = []
                this.form.equipments.forEach(el => equipmentIds.push(el.id))
                this.form.equipments = equipmentIds */
        if (this.form.id != 0) {
          await this.changeVehicleUseCaseImpl(this.form.id, this.form)
          snackbar.show({ message: "Alterado com sucesso!!!", color: "#008000", timeout: 3000 })
        } else {
          await this.createVehicleUseCaseImpl(this.form)
          snackbar.show({ message: "Adicionado com sucesso!!!", color: "#008000", timeout: 3000 })
        }

        await this.refreshUseCaseImpl()
      } catch (error) {
        snackbar.show({ message: error.toString(), color: "#ff0000", timeout: 3000 })
      } finally {
        this.paginate()
        this.loading = false
        this.dialog = false
      }
    }
  }

  async generateCode() {
    if (this.context.$refs.crudGenerateCodeVehicle.validate()) {
      try {
        this.loading = true
        this.formCode.equipmentID = this.formCode.equipmentID.id
        this.code = await this.createReleaseUseCaseImpl(this.formCode)
        this.dialogGenerateQrCodeCreated = true

        setTimeout(() => {
          this.dialogGenerateQrCodeCreated = false
          this.code = {}
        }, 15000)
      } catch (error) {
        snackbar.show({ message: error, color: "#ff0000", timeout: 3000 })
      } finally {
        this.paginate()
        this.loading = false
        this.dialogGenerateQrCode = false
      }
    }
  }

  change(item) {
    this.dialog = true
    this.form = structuredClone(item)
  }

  /*   async delete(item) {
      try {
        const confirm = await snackbar.confirm({ message: "Deseja realmente excluir o registro?", color: "primary", timeout: 5000 })
        if (confirm) {
          await this.deleteVehicleUseCaseImpl(item.id)
          this.paginate()
          snackbar.show({ message: "Resgistro excluído com sucesso!", color: "#008000", timeout: 1000 })
        }
      } catch (error) {
        snackbar.show({ message: error.toString() })
      }
    } */

  changeSerialName(item) {
    this.formCode.serial = item.name
  }


  watchOptions() {
    this.paginate()
  }
  watchSearch() {
    this.paginate()
  }
  watchClientOptions() {
    this.clientPaginate()
  }
  watchClientSearch() {
    this.clientPaginate()
  }
  watchEquipmentOptions() {
    this.equipmentPaginate()
  }
  watchEquipmentSearch() {
    this.equipmentPaginate()
  }

  async exportExcel() {
    try {
      const heading = [['ID', 'PLACA', 'ID CLIENTE', 'ATIVO', 'MODELO', 'ANO', 'EXCLUIDO', 'DATA CRIACAO', 'CLIENTE', 'EQUIPAMENTOS']];
      this.loadingBtnExcel = true
      const paginate = { ...this.options }
      paginate.itemsPerPage = 30000
      const data = await this.fetchVehicleUseCaseImpl(paginate, "")

      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet([]);
      XLSX.utils.sheet_add_aoa(ws, heading);
      XLSX.utils.sheet_add_json(ws, data.items.map((item) => {
        const i = JSON.parse(JSON.stringify(item))
        return i
      }), { origin: 'A2', skipHeader: true })
      XLSX.utils.book_append_sheet(wb, ws, 'vehicle');

      XLSX.writeFile(wb, `veiculos.xlsx`);
    } catch (error) {
      snackbar.show({ message: error.toString() })
    } finally {
      this.loadingBtnExcel = false
    }
  }

  changeStatus() {
    this.paginate()
  }

  isSelected(item) {
    return this.form.equipments.some((el) => el.id === item.id);
  }
  toggleItem(item) {
    if (this.form.equipments.some((el) => el.id === item.id)) {
      if (this.form.equipments.length > 1) {
        this.removeItem(item);
      }
    } else {
      this.form.equipments.push(item);
    }
  }
  removeItem(item) {
    if (this.form.equipments.some((el) => el.id == item.id)) {
      let index = this.form.equipments.map((e) => e.id).indexOf(item.id);
      if (index > -1) {
        this.form.equipments.splice(index, 1);
      }
    }
  }

}

export default VehicleController