
const createAccountUseCase = (repository) => async (data) => {
    try {
        return await repository(data);
    } catch (error) {
        console.log(error);
        throw error
    }
}

export default createAccountUseCase;

