<template>
  <div>
    <v-data-table
      class="astra-table"
      dense
      height="74vh"
      fixed-header
      :headers="controller.headers"
      :options.sync="controller.options"
      :server-items-length="controller.accountPagination.total"
      :items="controller.accountPagination.items"
      :loading="controller.loading"
      :footer-props="{
        'items-per-page-options': [40, 80],
      }"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <div>
          <v-btn
            icon
            class="px-2"
          >
            <v-icon
              color="#FBC02D"
              @click="controller.changeVehicleEquipment(item)"
              small
            >mdi-account-key</v-icon>
          </v-btn>
          <v-btn
            icon
            @click="controller.change(item)"
            class="px-2"
            v-if="hadPermission(7,2)"
          >
            <v-icon
              color="editBtn"
              small
            >mdi-pencil</v-icon>
          </v-btn>
          <!--           <v-btn icon @click="controller.delete(item)" class="px-2">
            <v-icon color="deleteBtn" small>mdi-delete</v-icon>
          </v-btn> -->
        </div>
      </template>

      <template v-slot:top>
        <v-row
          class="pa-3"
          no-gutters
        >
          <v-col
            :class="
              $vuetify.breakpoint.smAndDown
                ? 'py-3 d-flex align-center justify-center'
                : 'd-flex align-center'
            "
            cols="12"
            sm="12"
            md="6"
            lg="6"
            xl="6"
          >
            <span class="table-title">Usuários</span>
          </v-col>
          <v-col
            class="d-flex align-center justify-end"
            cols="12"
            sm="12"
            md="6"
            lg="6"
            xl="6"
          >
            <div style="width: 200px">
              <v-select
                outlined
                dense
                color="primary"
                v-model="controller.statusSelected"
                :items="controller.statusList"
                hide-details
                class="pa-2 search-tf"
                @change="controller.changeStatus()"
                item-text="text"
                item-value="value"
              ></v-select>
            </div>
            <v-text-field
              class="px-2 search-tf"
              style="max-width: 250px"
              outlined
              color="primary"
              hide-details
              dense
              placeholder="Pesquisar..."
              v-model="controller.search"
            ></v-text-field>
            <v-dialog
              max-width="600"
              persistent
              v-model="controller.dialog"
              scrollable
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="action-btn d-flex justify-center"
                  height="40"
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  tile
                  @click.stop="controller.open()"
                  v-if="hadPermission(7,1)"
                >
                  <v-icon left>mdi-plus</v-icon>
                  Adicionar
                </v-btn>
              </template>
              <v-card class="crud-card">
                <v-card-title>
                  <v-spacer></v-spacer>
                  <span class="table-title">Usuários</span>
                  <v-spacer></v-spacer>
                </v-card-title>
                <v-card-text class="pt-3 pb-1">
                  <v-form
                    ref="crud"
                    lazy-validation
                  >
                    <v-row>
                      <v-col>
                        <v-col
                          cols="12"
                          class="d-flex"
                        >
                          <!-- <v-switch
                            :v-model="false"
                            inset
                            label="Usuario bloqueado"
                          ></v-switch>
                          <v-spacer></v-spacer> -->
                          <v-switch
                            v-model="controller.form.changePassword"
                            inset
                            label="Alterar senha no próximo acesso"
                          ></v-switch>
                        </v-col>
                        <div class="form-input-group">
                          <v-text-field
                            class="search-tf"
                            single-line
                            id="name"
                            :rules="[(v) => !!v || 'Campo obrigatório']"
                            background-color="transparent"
                            outlined
                            required
                            dense
                            placeholder="Nome do usuário"
                            v-model="controller.form.name"
                          />
                          <label for="name">Nome do usuário</label>
                        </div>
                        <div class="form-input-group">
                          <v-text-field
                            class="search-tf"
                            single-line
                            id="email"
                            :rules="[controller.emailRules]"
                            background-color="transparent"
                            outlined
                            required
                            dense
                            placeholder="Email"
                            v-model="controller.form.email"
                          />
                          <label for="email">Email</label>
                        </div>
                        <div class="form-input-group">
                          <v-text-field
                            class="search-tf"
                            single-line
                            id="password"
                            type="password"
                            :rules="controller.form.id == 0 ? [(v) => !!v || 'Campo obrigatório'] :  []"
                            background-color="transparent"
                            outlined
                            required
                            dense
                            placeholder="Senha"
                            v-model="controller.form.password"
                          />
                          <label for="password">Senha</label>
                        </div>
                        <div
                          class="form-input-group"
                          v-if="hadPermission(0,0)"
                        >
                          <v-autocomplete
                            class="search-tf"
                            single-line
                            id="client"
                            background-color="transparent"
                            outlined
                            required
                            dense
                            placeholder="Cliente"
                            v-model="controller.form.clientID"
                            :search-input.sync="controller.searchClient"
                            :items="controller.clientPagination.items"
                            item-text="name"
                            item-value="id"
                          />
                          <label for="client">Cliente</label>
                        </div>
                        <div class="form-input-group">
                          <v-autocomplete
                            class="search-tf"
                            single-line
                            id="group"
                            :rules="[(v) => !!v || 'Campo obrigatório']"
                            background-color="transparent"
                            outlined
                            required
                            dense
                            placeholder="Grupos de usuários"
                            v-model="controller.form.accountGroupID"
                            :items="controller.userGroup"
                            item-text="name"
                            item-value="id"
                          />
                          <label for="group">Grupos de usuários</label>
                        </div>
                      </v-col>
                    </v-row>
                  </v-form>
                  <v-divider></v-divider>
                </v-card-text>
                <v-card-actions class="px-6 pb-3">
                  <v-btn
                    class="action-btn d-flex justify-center"
                    height="36"
                    width="114"
                    color="primary"
                    tile
                    outlined
                    @click="controller.close()"
                  >
                    <v-icon left>mdi-close</v-icon>
                    Cancelar
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    class="action-btn d-flex justify-center"
                    height="36"
                    width="114"
                    color="primary"
                    tile
                    @click="controller.create()"
                    :loading="controller.loading"
                  >
                    <v-icon left>mdi-content-save</v-icon>
                    Salvar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
      </template>
    </v-data-table>
    <v-dialog
      persistent
      max-width="1000"
      scrollable
      v-model="controller.vehiclesAndEquipDialog"
    >
      <v-card class="crud-card">
        <v-card-title>
          <v-spacer></v-spacer>
          <span class="table-title">
            Veículos e equipamentos visíveis para o usuário
            <b>{{ controller.userName }}</b>.</span>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-0">
          <v-row no-gutters>
            <v-col
              cols="12"
              sm="12"
              md="6"
              class="pa-6 d-flex flex-column"
            >
              <div class="form-input-group">
                <v-autocomplete
                  class="search-tf"
                  single-line
                  id="vehicle"
                  background-color="transparent"
                  outlined
                  required
                  dense
                  placeholder="Buscar veículos"
                  :search-input.sync="controller.searchVehicle"
                  :items="controller.vehiclePagination.items"
                  item-text="plate"
                  item-value="id"
                >
                  <template #item="{ item }">
                    <v-list-item @click="controller.toggleItemVehicle(item)">
                      <div>
                        <v-simple-checkbox
                          v-ripple
                          color="accent"
                          :value="controller.isSelectedVehicle(item)"
                          @click="controller.toggleItemVehicle(item)"
                        />
                      </div>
                      <div class="ml-2 d-flex align-center">
                        <span class="pl-2">{{ item.plate }}</span>
                      </div>
                    </v-list-item>
                  </template>
                </v-autocomplete>
                <label for="vehicle">Buscar veículos</label>
              </div>
              <div
                class="d-flex flex-column align-center"
                style="min-height: 200px"
              >
                <h3>Veículos selecionados:</h3>
                <div
                  class="my-2 d-flex flex-wrap justify-center"
                  v-if="controller.vehicleSelected.length > 0"
                >
                  <div
                    v-for="(equip, i) in controller.vehicleSelected"
                    :key="i"
                    class="pl-4 d-flex align-center equip-chip"
                  >
                    <b>{{ equip.plate }}</b>
                    <v-btn
                      @click="controller.removeItemVehicle(equip)"
                      icon
                    >
                      <v-icon
                        size="20"
                        color="#ff0000"
                      >mdi-close</v-icon>
                    </v-btn>
                  </div>
                </div>
                <div v-else>
                  <span>Nenhum veículo selecionado</span>
                </div>
              </div>
            </v-col>
            <v-divider :vertical="$vuetify.breakpoint.mdAndUp"></v-divider>
            <v-col
              cols="12"
              sm="12"
              md="6"
              class="pa-6 d-flex flex-column"
            >
              <div class="form-input-group">
                <v-autocomplete
                  class="search-tf"
                  single-line
                  id="equipment"
                  background-color="transparent"
                  outlined
                  required
                  dense
                  placeholder="Buscar equipamentos"
                  :search-input.sync="controller.searchEquipment"
                  :items="controller.equipmentPagination.items"
                  item-text="name"
                  item-value="id"
                >
                  <template #item="{ item }">
                    <v-list-item @click="controller.toggleItemEquipment(item)">
                      <div>
                        <v-simple-checkbox
                          v-ripple
                          color="accent"
                          :value="controller.isSelectedEquipment(item)"
                          @click="controller.toggleItemEquipment(item)"
                        />
                      </div>
                      <div class="ml-2 d-flex align-center">
                        <span class="pl-2">{{ item.name }}</span>
                      </div>
                    </v-list-item>
                  </template>
                </v-autocomplete>
                <label for="equipment">Buscar equipamentos</label>
              </div>
              <div
                class="d-flex flex-column align-center"
                style="min-height: 200px"
              >
                <h3>Equipamentos selecionados:</h3>
                <div
                  class="my-2 d-flex flex-wrap justify-center"
                  v-if="controller.equipmentSelected.length > 0"
                >
                  <div
                    v-for="(equip, i) in controller.equipmentSelected"
                    :key="i"
                    class="pl-4 d-flex align-center equip-chip"
                  >
                    <b>{{ equip.name }}</b>
                    <v-btn
                      @click="controller.removeItemEquipment(equip)"
                      icon
                    >
                      <v-icon
                        size="20"
                        color="#ff0000"
                      >mdi-close</v-icon>
                    </v-btn>
                  </div>
                </div>
                <div v-else>
                  <span>Nenhum equipamento selecionado</span>
                </div>
              </div>
            </v-col>
          </v-row>
          <!--           <v-row no-gutters>
            <v-col cols="6" class="mini-table-border">
              <v-data-table
                class="astra-table"
                dense
                height="50vh"
                fixed-header
                :headers="controller.vehicleHeaders"
                :options.sync="controller.vehicleOptions"
                :server-items-length="controller.vehiclePagination.total"
                :items="controller.vehiclePagination.items"
                :loading="controller.vehicleTableLoading"
                :footer-props="{
                  'items-per-page-options': [40, 80],
                }"
                show-select
                v-model="controller.selectedVehicles"
              >
                <template v-slot:top>
                  <v-row class="pa-3" no-gutters>
                    <v-col cols="12" class="d-flex align-center">
                      <span class="table-title">Veículos</span>
                      <v-spacer></v-spacer>
                      <v-text-field
                        class="px-2 search-tf"
                        style="max-width: 250px"
                        outlined
                        color="primary"
                        hide-details
                        dense
                        placeholder="Pesquisar..."
                        v-model="controller.searchVehicle"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </template>
              </v-data-table>
            </v-col>
            <v-col cols="6" class="mini-table-border">
              <v-data-table
                class="astra-table"
                dense
                height="50vh"
                fixed-header
                :headers="controller.equipmentHeaders"
                :options.sync="controller.equipmentOptions"
                :server-items-length="controller.equipmentPagination.total"
                :items="controller.equipmentPagination.items"
                :loading="controller.equipmentTableLoading"
                :footer-props="{
                  'items-per-page-options': [40, 80],
                }"
                show-select
                v-model="controller.selectedEquipments"
              >
                <template v-slot:top>
                  <v-row class="pa-3" no-gutters>
                    <v-col cols="12" class="d-flex align-center">
                      <span class="table-title">Equipamentos</span>
                      <v-spacer></v-spacer>
                      <v-text-field
                        class="px-2 search-tf"
                        style="max-width: 250px"
                        outlined
                        color="primary"
                        hide-details
                        dense
                        placeholder="Pesquisar..."
                        v-model="controller.searchEquipment"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </template>
              </v-data-table>
            </v-col>
          </v-row> -->
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="px-6 pb-3">
          <v-btn
            class="action-btn d-flex justify-center"
            height="36"
            width="114"
            color="primary"
            tile
            outlined
            @click="controller.vehiclesAndEquipDialog = false"
          >
            <v-icon left>mdi-close</v-icon>
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            class="action-btn d-flex justify-center"
            height="36"
            width="114"
            color="primary"
            tile
            @click="controller.saveVehicleEquipmentVisibility()"
            :loading="controller.loading"
          >
            <v-icon left>mdi-content-save</v-icon>
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

 <script>
import { accountController } from "@/di/di";
import _ from "lodash";
export default {
  name: "account-page",
  data: (context) => ({
    controller: accountController(context),
  }),
  mounted() {
    this.controller.mounted();
  },
  watch: {
    "controller.options"() {
      this.controller.watchOptions();
    },
    "controller.search"() {
      this.controller.watchSearch();
    },
    "controller.clientOptions"() {
      this.controller.watchClientOptions();
    },
    "controller.searchClient": _.debounce(function () {
      this.controller.watchClientSearch();
    }, 500),
    "controller.equipmentOptions"() {
      this.controller.watchEquipmentOptions();
    },
    "controller.searchEquipment": _.debounce(function () {
      this.controller.watchEquipmentSearch();
    }, 500),
    "controller.vehicleOptions"() {
      this.controller.watchVehicleOptions();
    },
    "controller.searchVehicle": _.debounce(function () {
      this.controller.watchVehicleSearch();
    }, 500),
  },
};
</script> 

<style scoped lang="scss">
.astra-table {
  border-radius: 20px;
  background-color: var(--v-secondary-base) !important;
}

.toolbar-table {
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
}

::v-deep .v-data-table.v-data-table--fixed-header thead th {
  background-color: var(--v-secondary-base) !important;
}

.action-btn {
  text-transform: none;
  border-radius: 10px !important;
}

.search-tf {
  border-radius: 10px !important;
}

.table-title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
}

.crud-card {
  border-radius: 20px;
  background-color: var(--v-secondary-base) !important;
}

::v-deep .v-dialog {
  border-radius: 20px !important;
}

label {
  order: -1;
}

.v-input:focus-within + label {
  color: var(--v-primary-base) !important;
}

.form-input-group {
  display: flex;
  flex-direction: column;
}

::v-deep tbody {
  tr:hover {
    background-color: var(--v-tertiary-base) !important;
  }
}

.mini-table-border {
  border: 2px solid var(--v-tertiary-base);
  border-radius: 8px;
}

.equip-chip {
  background-color: var(--v-tertiary-base);
  border-radius: 8px;
  margin: 2px;
}
</style>
