import Handler from '@/core/domain/usecase/handler';

const fetchEquipmentRepository = (axios) => async (pagination) => {
    try {
        const response = await axios.get('/equipment', {
            params: pagination
        })
        return response.data
    } catch (err) {
        if (err.isAxiosError) {
            throw new Handler().trataErro(err)
        } else {
            throw err
        }
    }
}

export default fetchEquipmentRepository