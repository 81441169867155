<template>
  <div
    id="root-login"
    class="background"
  >
    <div class="back-btn">
      <v-btn
        color="primary"
        v-if="controller.tab == 1"
        @click="controller.tab = 0"
        height="40"
        min-height="0"
        width="40"
        min-width="0"
        style="border-radius: 20px; border: 1px solid green"
      >
        <v-icon color="secondary">mdi-chevron-left</v-icon>
      </v-btn>
    </div>

    <v-stepper
      elevation="0"
      class="stepper-login background"
      v-model="controller.tab"
    >
      <v-stepper-items>
        <v-stepper-content step="0">
          <v-card
            elevation="0"
            width="500"
            min-width="200"
            color="background"
          >
            <v-card-text>
              <v-row>
                <v-col
                  cols="12"
                  class="d-flex justify-center"
                >
                  <v-img
                    :src="imageLogin"
                    max-width="300"
                    max-height="400"
                  ></v-img>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  class="d-flex justify-center"
                >
                  <v-hover v-slot="{ hover }">
                    <v-card
                      width="200"
                      elevation="0"
                      class="mr-2 card-button"
                      @click="controller.releaseDriver()"
                    >
                      <v-card-text>
                        <v-row>
                          <v-col
                            cols="12"
                            class="d-flex justify-center"
                          >
                            <v-icon
                              size="60"
                              :color="hover ? 'primary' : ''"
                            >mdi-truck-fast-outline</v-icon>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col
                            cols="12"
                            class="d-flex justify-center"
                          >
                            <span
                              v-if="hover"
                              class="card-title"
                              style="color: var(--v-primary-base)"
                            >LIBERAR VEÍCULO</span>
                            <span
                              v-else
                              class="card-title"
                            >LIBERAR VEÍCULO</span>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-hover>
                  <v-hover v-slot="{ hover }">
                    <v-card
                      @click="controller.tab = 1"
                      width="200"
                      elevation="0"
                      class="ml-2 card-button"
                    >
                      <v-card-text>
                        <v-row>
                          <v-col
                            cols="12"
                            class="d-flex justify-center"
                          >
                            <v-icon
                              size="60"
                              :color="hover ? 'primary' : ''"
                            >mdi-laptop</v-icon>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col
                            cols="12"
                            class="d-flex justify-center"
                          >
                            <span
                              v-if="hover"
                              class="card-title"
                              style="color: var(--v-primary-base)"
                            >ACESSAR SISTEMA</span>
                            <span
                              v-else
                              class="card-title"
                            >ACESSAR SISTEMA</span>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-hover>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-stepper-content>
        <v-stepper-content step="1">
          <v-card
            tile
            width="500"
            min-width="200"
            color="background"
            
          >
            <v-card-text>
              <v-row>
                <v-col
                  cols="12"
                  class="d-flex justify-center"
                >
                  <v-img
                    :src="imageLogin"
                    max-width="300"
                    max-height="400"
                  ></v-img>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col cols="12">
                  <v-form ref="loginForm">
                    <div class="pt-4 form-input-group">
                      <v-text-field
                        class="text-field"
                        id="email"
                        background-color="transparent"
                        v-model="controller.email"
                        :rules="[controller.emailRules]"
                        prepend-inner-icon="mdi-email-outline"
                        outlined
                        required
                        placeholder="Informe seu e-mail"
                      >
                      </v-text-field>
                      <label for="email">E-mail</label>
                    </div>
                    <div class="form-input-group">
                      <v-text-field
                        class="text-field"
                        id="password"
                        :rules="[controller.requiredRules]"
                        background-color="transparent"
                        v-model="controller.password"
                        outlined
                        required
                        prepend-inner-icon="mdi-lock-outline"
                        @click:append="
                          controller.showPassword = !controller.showPassword
                        "
                        :append-icon="
                          controller.showPassword
                            ? 'mdi-eye-outline'
                            : 'mdi-eye-off-outline'
                        "
                        :type="controller.showPassword ? 'text' : 'password'"
                        placeholder="Informe a senha"
                      />
                      <label for="password">Senha</label>
                    </div>
                  </v-form>
                </v-col>
                <v-col cols="12">
                  <v-btn
                    @click="controller.login()"
                    :loading="controller.loading"
                    block
                    color="primary"
                    style="text-transform: none; height: 45px; z-index: 9999"
                  ><span>Entrar</span></v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-stepper-content>
        <v-stepper-content step="2">
          <v-card
            tile
            width="500"
            min-width="200"
            color="background"
            
          >
            <v-card-text>
              <v-row>
                <v-col
                  cols="12"
                  class="d-flex justify-center"
                >
                  <v-img
                    :src="imageLogin"
                    max-width="300"
                    max-height="400"
                  ></v-img>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col cols="12">
                  <v-form ref="loginForm" style="z-index: 2">
                    <div class="form-input-group">
                      <v-text-field
                        class="text-field"
                        id="password"
                        :rules="[controller.requiredRules]"
                        background-color="transparent"
                        v-model="controller.password"
                        outlined
                        required
                        prepend-inner-icon="mdi-lock-outline"
                        @click:append="
                          controller.showPassword = !controller.showPassword
                        "
                        :append-icon="
                          controller.showPassword
                            ? 'mdi-eye-outline'
                            : 'mdi-eye-off-outline'
                        "
                        :type="controller.showPassword ? 'text' : 'password'"
                        placeholder="Crie uma nova senha"
                      />
                      <label for="password">Nova senha</label>
                    </div>
                  </v-form>
                </v-col>
                <v-col cols="12">
                  <v-btn
                    @click="controller.updatePassword()"
                    :loading="controller.loading"
                    block
                    color="primary"
                    style="text-transform: none; height: 45px; z-index: 9999"
                  ><span>Alterar</span></v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>

    <div class="custom-shape-divider-bottom-1656682232" style="z-index: 1">
      <svg
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1200 120"
        preserveAspectRatio="none"
      >
        <path
          d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
          class="shape-fill"
        ></path>
      </svg>
    </div>
  </div>
</template>

<script>
import { loginController } from "@/di/di";
export default {
  name: "login-page",
  data: (context) => ({
    controller: loginController(context),
  }),
  mounted() {
    this.controller.mounted();
  },
  computed: {
    imageLogin() {
      return process.env.VUE_APP_IMAGE_LOGIN
    }
  }
};
</script>

<style scoped>
#root-login {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-shape-divider-bottom-1656682232 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.card-button {
  border: 2px solid var(--v-secondary-base) !important;
  background-color: var(--v-background-base) !important;
  border-radius: 14px !important;
}

.card-button:hover {
  border: 2px solid var(--v-primary-base) !important;
}

.card-title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
}

.custom-shape-divider-bottom-1656682232 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 157px;
}

.custom-shape-divider-bottom-1656682232 .shape-fill {
  fill: var(--v-primary-base);
}

label {
  order: -1;
}

.stepper-login {
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.v-input:focus-within + label {
  color: var(--v-primary-base) !important;
}

.form-input-group {
  display: flex;
  flex-direction: column;
}

.back-btn {
  position: absolute;
  top: 20px;
  left: 20px;
}
</style>
