import Handler from '@/core/domain/usecase/handler';

const createAccountRepository = (axios) => async (data) => {
  try {
    const response = await axios.post(`/account`,
      data
    )
    return response.data
  } catch (err) {
    if (err.isAxiosError) {
      throw new Handler().trataErro(err)
    } else {
      throw err
    }
  }
}

export default createAccountRepository
