<template>
  <div>
    <v-data-table
      class="astra-table"
      dense
      height="76vh"
      fixed-header
      :headers="controller.headers"
      :options.sync="controller.options"
      :server-items-length="controller.codePagination.total"
      :items="controller.codePagination.items"
      :loading="controller.loading"
      :footer-props="{
        'items-per-page-options': [40, 80],
      }"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <div>
          <v-btn
            v-if="hadPermission(5,2)"
            icon
            @click="controller.change(item)"
            class="px-2"
          >
            <v-icon
              color="editBtn"
              small
            >mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            v-if="hadPermission(5,3)"
            icon
            @click="controller.delete(item)"
            class="px-2"
          >
            <v-icon
              color="deleteBtn"
              small
            >mdi-delete</v-icon>
          </v-btn>
        </div>
      </template>

      <template v-slot:top>
        <v-row
          class="pa-3"
          no-gutters
        >
          <v-col
            :class="
              $vuetify.breakpoint.smAndDown
                ? 'py-3 d-flex align-center justify-center'
                : 'd-flex align-center'
            "
            cols="12"
            sm="12"
            md="6"
            lg="6"
            xl="6"
          >
            <span class="table-title">Códigos</span>
          </v-col>
          <v-col
            class="d-flex align-center justify-end"
            cols="12"
            sm="12"
            md="6"
            lg="6"
            xl="6"
          >
            <v-text-field
              class="px-2 search-tf"
              style="max-width: 250px"
              outlined
              color="primary"
              hide-details
              dense
              placeholder="Pesquisar..."
              v-model="controller.search"
            ></v-text-field>
            <v-dialog
              max-width="600"
              persistent
              scrollable
              v-model="controller.dialog"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="hadPermission(5,1)"
                  class="action-btn d-flex justify-center"
                  height="40"
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  tile
                  @click.stop="controller.open()"
                >
                  <v-icon left>mdi-plus</v-icon>
                  Adicionar
                </v-btn>
              </template>
              <v-card class="crud-card">
                <v-card-title>
                  <v-spacer></v-spacer>
                  <span class="table-title">Código</span>
                  <v-spacer></v-spacer>
                </v-card-title>
                <v-card-text class="pt-3 pb-1">
                  <v-form
                    ref="crud"
                    lazy-validation
                  >
                    <v-row>
                      <v-col>
                        <div class="form-input-group">
                          <v-text-field
                            class="search-tf"
                            single-line
                            id="cod"
                            :rules="[(v) => !!v || 'Campo obrigatório']"
                            background-color="transparent"
                            outlined
                            required
                            dense
                            placeholder="Código"
                            v-model="controller.form.name"
                          />
                          <label for="cod">Código</label>
                        </div>
                        <div class="form-input-group">
                          <v-text-field
                            class="search-tf"
                            single-line
                            id="password"
                            :rules="[
                              (v) => !!v || 'Campo obrigatório',
                              (v) =>
                                (!!v && v.length < 7) || 'Máximo 6 caracteres',
                            ]"
                            background-color="transparent"
                            outlined
                            required
                            dense
                            placeholder="Senha"
                            v-model="controller.form.password"
                          />
                          <label for="password">Senha</label>
                        </div>
                      </v-col>
                    </v-row>
                  </v-form>
                  <v-divider></v-divider>
                </v-card-text>
                <v-card-actions class="px-6 pb-3">
                  <v-btn
                    class="action-btn d-flex justify-center"
                    height="36"
                    width="114"
                    color="primary"
                    tile
                    outlined
                    @click="controller.close()"
                  >
                    <v-icon left>mdi-close</v-icon>
                    Cancelar
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    class="action-btn d-flex justify-center"
                    height="36"
                    width="114"
                    color="primary"
                    tile
                    @click="controller.create()"
                    :loading="controller.loading"
                  >
                    <v-icon left>mdi-content-save</v-icon>
                    Salvar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
      </template>
    </v-data-table>
  </div>
</template>

 <script>
import { codeController } from "@/di/di";
export default {
  name: "code-page",
  data: (context) => ({
    controller: codeController(context),
  }),
  watch: {
    "controller.options"() {
      this.controller.watchOptions();
    },
    "controller.search"() {
      this.controller.watchSearch();
    },
  },
};
</script> 

<style scoped lang="scss">
.astra-table {
  border-radius: 20px;
  background-color: var(--v-secondary-base) !important;
}

.toolbar-table {
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
}

::v-deep .v-data-table.v-data-table--fixed-header thead th {
  background-color: var(--v-secondary-base) !important;
}

.action-btn {
  text-transform: none;
  border-radius: 10px !important;
}

.search-tf {
  border-radius: 10px !important;
}

.table-title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
}

.crud-card {
  border-radius: 20px;
  background-color: var(--v-secondary-base) !important;
}

::v-deep .v-dialog {
  border-radius: 20px !important;
}

label {
  order: -1;
}

.v-input:focus-within + label {
  color: var(--v-primary-base) !important;
}

.form-input-group {
  display: flex;
  flex-direction: column;
}

::v-deep tbody {
  tr:hover {
    background-color: var(--v-tertiary-base) !important;
  }
}
</style>
