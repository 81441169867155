export const headers = [
  {
    text: 'Placa',
    align: 'start',
    sortable: true,
    value: 'plate',
    width: '15%'
  },
  {
    text: 'Cliente',
    align: 'start',
    sortable: true,
    value: 'client',
    width: '15%'
  },
  {
    text: 'Modelo',
    align: 'start',
    sortable: true,
    value: 'model',
    width: '10%'
  },
  {
    text: 'Ano',
    align: 'start',
    sortable: true,
    value: 'year',
    width: '15%'
  },
  {
    text: 'Ativo',
    align: 'start',
    sortable: true,
    value: 'active',
    width: '5%'
  },
  {
    text: 'Ações',
    value: 'actions',
    align: 'right',
    sortable: false,
    width: '10%'
  }
]

