
const changeEquipmentUseCase = (repository) => async (id, data) => {
    if (data.equipmentTypeID !== 2 && data.equipmentTypeID !== 2)  {
        data.mac = null
    }
    return await repository(id, data);
}

export default changeEquipmentUseCase;

