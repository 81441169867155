export const headers = [
  {
    text: 'Nome',
    align: 'start',
    sortable: true,
    value: 'name',
    width: '15%'
  },
  {
    text: 'CPF/CNPJ',
    align: 'start',
    sortable: true,
    value: 'cpfCnpj',
    width: '15%'
  },
  {
    text: 'Telefone',
    align: 'start',
    sortable: true,
    value: 'phone',
    width: '10%'
  },
  {
    text: 'Cidade',
    align: 'start',
    sortable: true,
    value: 'cityName',
    width: '15%'
  },
  {
    text: 'Ativo',
    align: 'start',
    sortable: true,
    value: 'active',
    width: '5%'
  },
  {
    text: 'Associado',
    align: 'start',
    sortable: true,
    value: 'associate',
    width: '10%'
  },
  {
    text: 'Associação',
    align: 'start',
    sortable: true,
    value: 'nameAssociation',
    width: '15%'
  },
  {
    text: 'Veículos',
    align: 'start',
    sortable: true,
    value: 'qtVehicles',
    width: '5%'
  },
  {
    text: 'Ações',
    value: 'actions',
    align: 'right',
    sortable: false,
    width: '10%'
  }
]

