import RefreshUseCase from '@/core/domain/usecase/refresh_usecase';
import LoginPage from '@/module/login/view/login.vue'
import HomePage from '@/module/home/view/home_page.vue'
import { isChangePassword } from '@/core/domain/model/jwtAuthToken';

class DefaultController {
    context = null;
    selected = null;
    // refreshUseCase = RefreshUseCase

    constructor(
        context,
        refreshUseCase
    ) {
        this.context = context;
        this.refreshUseCase = refreshUseCase
    }

    async mounted() {
        window.router = this;
        try {
            if (localStorage.getItem('auth') == null) {
                this.selected = LoginPage
                return
            }

            const change = isChangePassword()

            if (change) {
                localStorage.removeItem('auth')
                this.selected = LoginPage
                return
            }

            this.selected = HomePage
        } catch (error) {
            this.selected = LoginPage
        }
    }
    login() {
        localStorage.removeItem('auth')
        this.selected = LoginPage
    }

    home() {
        this.selected = HomePage
    }
}

export default DefaultController