import axios from 'axios';

const refreshRepostory = (baseUrl) => async (jwtAuthToken) => {
    const response = await axios.get(baseUrl + '/session', {
        headers: {
            authorization: `Bearer ${jwtAuthToken.token} ${jwtAuthToken.refresh} ${jwtAuthToken.type}`
        }
    })
    const newJwtAuthToken = response.data
    return newJwtAuthToken
}

export default refreshRepostory