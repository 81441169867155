import Vue from 'vue';
import { getAccountGroupID } from "./jwtAuthToken"


export function hadPermission(permissionID, actionID) { 
    const accountGroupID = getAccountGroupID()

    if (accountGroupID === 1 ) {
        return true
    }
    
    if (Vue.prototype.permissions) {
        const had = Vue.prototype.permissions.some((permission) => permission.actionID == actionID && permission.permissionID == permissionID)
        return had
    }

    return false    
}

export function hadScreenAccess(screenID) { 
    const accountGroupID = getAccountGroupID()

    if (accountGroupID === 1 ) {
        return true
    }
    
    if (Vue.prototype.screens) {
        const had = Vue.prototype.screens.some((screen) => screen == screenID)
        return had
    }

    return false    
}