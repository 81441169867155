class Vehicle {
  constructor(
    id,
    active,
    clientID,
    model,
    plate,
    year,
    equipments,
    description,
  ) {
    this.id = id
    this.active = active
    this.clientID = clientID
    this.model = model
    this.plate = plate
    this.year = year
    this.equipments = equipments
    this.description = description
  }

}

export const vehicleDefault = () => {
  const vehicle = {
    id: 0,
    active: true,
    clientID: 0,
    model: "",
    plate: "",
    year: "",
    equipments: [],
    description: null
  }
  return vehicle
}

export default Vehicle
