import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import vuetify from './plugins/vuetify'
import dayjs from 'dayjs'
import 'dayjs/locale/pt-br'
import { snackbar } from '@/core/controller/snackbar_controller';
import snackbarVue from "@/core/component/snackbar.vue";
import datePicker from "./core/component/date_picker.vue";
import { hadPermission } from './core/domain/model/permission'

window.snackbarController = snackbar
Vue.component('snackbar-component', snackbarVue)
Vue.component('date-picker', datePicker)

Vue.config.productionTip = false

Vue.prototype.dayjs = dayjs
Vue.prototype.hadPermission = hadPermission
export const bus = new Vue();

dayjs.locale('pt-br')

Vue.prototype.$imageLogin = '/astra.png'
window.imageLogin = '/astra.png'

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
