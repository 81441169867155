//import { snackbar } from "@/core/controller/snackbar_controller";
import { headers } from "../const/headers";
import { releaseDefault } from "../domain/release/release";

class ReleaseController {
  context = null;
  headers = headers
  loading = false
  searchClient = ""
  searchSerial = ""
  searchPlate = ""
  searchCode = ""
  searchModel = ""
  searchAccount = ""
  dialog = false
  form = releaseDefault()

  releasePagination = {
    total: 0,
    items: []
  }
  options = {
    page: 1,
    itemsPerPage: 40,
    groupBy: [],
    sortBy: ["id"],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    sortDesc: []
  }
  clientPagination = {
    total: 0,
    items: []
  }
  clientOptions = {
    page: 1,
    itemsPerPage: 40,
    groupBy: [],
    sortBy: ["id"],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    sortDesc: []
  }
  serialPagination = {
    total: 0,
    items: []
  }
  serialOptions = {
    page: 1,
    itemsPerPage: 40,
    groupBy: [],
    sortBy: ["id"],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    sortDesc: []
  }
  platePagination = {
    total: 0,
    items: []
  }
  plateOptions = {
    page: 1,
    itemsPerPage: 40,
    groupBy: [],
    sortBy: ["id"],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    sortDesc: []
  }
  codePagination = {
    total: 0,
    items: []
  }
  codeOptions = {
    page: 1,
    itemsPerPage: 40,
    groupBy: [],
    sortBy: ["id"],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    sortDesc: []
  }
  modelPagination = {
    total: 0,
    items: []
  }
  modelOptions = {
    page: 1,
    itemsPerPage: 40,
    groupBy: [],
    sortBy: ["id"],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    sortDesc: []
  }
  accountPagination = {
    total: 0,
    items: []
  }
  accountOptions = {
    page: 1,
    itemsPerPage: 40,
    groupBy: [],
    sortBy: ["id"],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    sortDesc: []
  }

  constructor(
    context,
    fetchReleaseUseCaseImpl,
    fetchAccountUseCaseImpl,
    fetchClientUseCaseImpl,
    fetchCodeUseCaseImpl,
    fetchModelUseCaseImpl,
    fetchVehicleUseCaseImpl,
    fetchEquipmentUseCaseImpl,
  ) {
    this.context = context;
    this.fetchReleaseUseCaseImpl = fetchReleaseUseCaseImpl
    this.fetchAccountUseCaseImpl = fetchAccountUseCaseImpl
    this.fetchClientUseCaseImpl = fetchClientUseCaseImpl
    this.fetchCodeUseCaseImpl = fetchCodeUseCaseImpl
    this.fetchModelUseCaseImpl = fetchModelUseCaseImpl
    this.fetchVehicleUseCaseImpl = fetchVehicleUseCaseImpl
    this.fetchEquipmentUseCaseImpl = fetchEquipmentUseCaseImpl
  }

  async paginate() {
    this.loading = true
    try {
      this.releasePagination = await this.fetchReleaseUseCaseImpl(this.options, this.form)
    } catch (error) {
      console.log(error);
    } finally {
      this.loading = false
    }
  }

  async paginateAccount() {
    try {
      this.accountPagination = await this.fetchAccountUseCaseImpl(this.accountOptions, this.searchAccount)
    } catch (error) {
      console.log(error);
    }
  }
  async paginateClient() {
    try {
      this.clientPagination = await this.fetchClientUseCaseImpl(this.clientOptions, this.searchClient)
    } catch (error) {
      console.log(error);
    }
  }
  async paginateCode() {
    try {
      this.codePagination = await this.fetchCodeUseCaseImpl(this.codeOptions, this.searchCode)
    } catch (error) {
      console.log(error);
    }
  }
  async paginateModel() {
    try {
      this.modelPagination = await this.fetchModelUseCaseImpl(this.modelOptions, this.searchModel)
    } catch (error) {
      console.log(error);
    }
  }
  async paginateVehicle() {
    try {
      this.platePagination = await this.fetchVehicleUseCaseImpl(this.plateOptions, this.searchPlate)
    } catch (error) {
      console.log(error);
    }
  }
  async paginateEquipment() {
    try {
      this.serialPagination = await this.fetchEquipmentUseCaseImpl(this.serialOptions, this.searchSerial)
    } catch (error) {
      console.log(error);
    }
  }


  watchOptions() {
    this.paginate()
  }
  watchOptionsClient() {
    this.paginateClient()
  }
  watchSearchClient() {
    this.paginateClient()
  }
  watchOptionsSerial() {
    this.paginateEquipment()
  }
  watchSearchSerial() {
    this.paginateEquipment()
  }
  watchOptionsPlate() {
    this.paginateVehicle()
  }
  watchSearchPlate() {
    this.paginateVehicle()
  }
  watchOptionsCode() {
    this.paginateCode()
  }
  watchSearchCode() {
    this.paginateCode()
  }
  watchOptionsModel() {
    this.paginateModel()
  }
  watchSearchModel() {
    this.paginateModel()
  }
  watchOptionsAccount() {
    this.paginateAccount()
  }
  watchSearchAccount() {
    this.paginateAccount()
  }

  open() {
    this.form = releaseDefault()
  }


  async filter() {
    try {
      this.releasePagination = await this.fetchReleaseUseCaseImpl(this.options, this.form)
      this.dialog = false
    } catch (error) {
      console.log(error);
    }
  }
}

export default ReleaseController