
const createClientUseCase = (repository) => async (data) => {
    try {
        if (!data.associate) {
            data.nameAssociation = null
        }
        return await repository(data);
    } catch (error) {
        console.log(error);
        throw error
    }
}

export default createClientUseCase;

