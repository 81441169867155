import { snackbar } from '@/core/controller/snackbar_controller';
import { getAccount, isChangePassword } from '@/core/domain/model/jwtAuthToken';
class LoginController {
    context = null
    loginUseCase = null
    email = ""
    password = ""
    loading = false
    tab = 0
    showPassword = false
    requiredRules = value => !!value || 'Campo obrigatório.'
    emailRules = value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || 'E-mail inválido.'
    }

    constructor(
        context,
        loginUseCase,
        changeAccountPasswordUseCase
    ) {
        this.context = context
        this.loginUseCase = loginUseCase
        this.changeAccountPasswordUseCase = changeAccountPasswordUseCase
    }

    mounted() {
        this.context.$vuetify.theme.dark = localStorage.getItem("dark") == "true" ? true : false
    }

    async login() {
        if (this.context.$refs.loginForm.validate()) {
            try {
                this.loading = true
                await this.loginUseCase(this.email, this.password)
                this.password = ""
                this.context.$refs.form?.resetValidation()

                const change = isChangePassword()

                if (change) {
                    this.tab = 2
                    this.context.$refs.form?.resetValidation()
                    return
                }

                this.email = ""
                window.router.home()

            } catch (error) {
                snackbar.show({ message: "Usuário ou senha inválido" })
            } finally {
                this.loading = false
            }
        }
    }

    async updatePassword() {
        try {
            this.loading = true
            const account = getAccount()
            account["password"] = this.password
            await this.changeAccountPasswordUseCase(account.id, account)
            await this.login()
        } catch (error) {
            snackbar.show({ message: "Não foi possível alterar sua senha!" })
        } finally {
            this.loading = false
        }
    }

    releaseDriver() {
        let wnd = window.open("http://192.168.4.1");
        setTimeout(() => {
            wnd.close();
        }, 2000);
    }
}

export default LoginController