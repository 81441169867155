import Handler from '@/core/domain/usecase/handler';

const getVisibleEquipmentsByAccountIDRepository = (axios) => async (id) => {
    try {
        const response = await axios.get(`account/${id}/equipment`)
        return response.data
    } catch (err) {
        if (err.isAxiosError) {
            throw new Handler().trataErro(err)
        } else {
            throw err
        }
    }
}

export default getVisibleEquipmentsByAccountIDRepository