import { bus } from '@/main'
class DashboardController {
  context = null

  constructor(
    context,
  ) {
    this.context = context
  }

  requestPage(id){
    bus.$emit('toPage', id)
  }

}

export default DashboardController