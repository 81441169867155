export const headers = [
  {
    text: 'Serial',
    align: 'start',
    sortable: true,
    value: 'name',
  },
  {
    text: 'Modelo',
    align: 'start',
    sortable: true,
    value: 'model',
  },
  {
    text: 'Data Cadastro',
    align: 'start',
    sortable: true,
    value: 'createdDate',
  },
  {
    text: 'Data Alteração Placa',
    align: 'start',
    sortable: true,
    value: 'updatedDatePlate',
  },
  {
    text: 'Usuário',
    align: 'start',
    sortable: true,
    value: 'account',
  },
  {
    text: 'Placa',
    align: 'start',
    sortable: true,
    value: 'plate',
  },
  {
    text: 'Tipo',
    align: 'start',
    sortable: true,
    value: 'type',
  },
  {
    text: 'Cliente',
    align: 'start',
    sortable: true,
    value: 'client',
  },
  {
    text: 'Endereço mac',
    align: 'start',
    sortable: true,
    value: 'mac',
  },
  {
    text: 'Ativo',
    align: 'start',
    sortable: true,
    value: 'active',
  },
  {
    text: 'Ações',
    value: 'actions',
    align: 'right',
    sortable: false,
    width: '15%'
  }
]