export const headers = [
  {
    text: 'Código',
    align: 'start',
    sortable: true,
    value: 'name',
    width: '45%'
  },
  {
    text: 'Senha',
    align: 'start',
    sortable: true,
    value: 'password',
    width: '45%'
  },
  {
    text: 'Ações',
    value: 'actions',
    align: 'right',
    sortable: false,
    width: '10%'
  }
]

