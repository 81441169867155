class Account {
  constructor(
    { 
      id,
      name,
      email,
      password = null,
      accountGroupID,
      clientID,
      changePassword,
      visibleVehicle,
      visibleEquipment,
    }
  ) {
    this.id = id
    this.name = name
    this.email = email
    this.password = password
    this.accountGroupID = accountGroupID
    this.clientID = clientID
    this.changePassword = changePassword
    this.visibleVehicle = visibleVehicle
    this.visibleEquipment = visibleEquipment
  }
}

export const accountDefault = () => {
  const account = {
    id: 0,
    name: "",
    password: "",
    email: "",
    accountGroupID: 0,
    clientID: null,
    changePassword: true,
    visibleVehicle: null,
    visibleEquipment: null
  }
  return account
}

export default Account