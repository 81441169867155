import Handler from '@/core/domain/usecase/handler';

const fetchModelRepository = (axios) => async (pagination) => {
    try {
        const response = await axios.get('/model', {
            params: pagination
        })

        const modelPagination = response.data
        return modelPagination
    } catch (err) {
        if (err.isAxiosError) {
            throw new Handler().trataErro(err)
        } else {
            throw err
        }
    }
}

export default fetchModelRepository