import Handler from '@/core/domain/usecase/handler';

const changeAccountRepository = (axios) => async (id, data) => {
  try {
    const response = await axios.put(`/account/${id}`,
      data
    )
    return response.data
  } catch (err) {
    if (err.isAxiosError) {
      throw new Handler().trataErro(err)
    } else {
      throw err
    }
  }
}

export default changeAccountRepository

