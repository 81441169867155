import Handler from '@/core/domain/usecase/handler';

const deleteModelRepository = (axios) => async (id) => {
    try {
        const response = await axios.delete(`/model/${id}`)
        const model = response.data
        return model
    } catch (err) {
        if (err.isAxiosError) {
            throw new Handler().trataErro(err)
        } else {
            throw err
        }
    }
}

export default deleteModelRepository