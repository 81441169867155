import Handler from '@/core/domain/usecase/handler';

const countEquipmentPlateRepository = (axios) => async () => {
    try {
        const response = await axios.get('/count/equipment/plate')
        return response.data
    } catch (err) {
        if (err.isAxiosError) {
            throw new Handler().trataErro(err)
        } else {
            throw err
        }
    }
}

export default countEquipmentPlateRepository