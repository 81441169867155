class Handler {

  trataErro(error) {
    if (error.isAxiosError) {
      switch (error.response.status) {
        case 500:
          return error.response.data.message;
        default:
          return error.response.data.message;
      }
    } else {
      switch (error.toString()) {
        case "TypeError: Cannot read property 'split' of null":
          window.location.href = "/";
          break;
        default:
          return error;
      }
      return error;
    }
  }
}

export default Handler;
