class Equipment {
  constructor(
    id,
    name,
    modelID,
    vehicleID,
    equipmentTypeID,
    clientID,
    active,
    description,
  ) {
    this.id = id
    this.name = name
    this.modelID = modelID
    this.vehicleID = vehicleID
    this.equipmentTypeID = equipmentTypeID
    this.clientID = clientID
    this.active = active
    this.description = description
  }
}

export const equipmentDefault = () => {
  const equipment = {
    id: 0,
    name: "",
    modelID: 0,
    vehicleID: null,
    equipmentTypeID: 0,
    clientID: null,
    active: true,
    description: null
  }
  return equipment
}

export default Equipment

