var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"astra-table",attrs:{"dense":"","height":"76vh","fixed-header":"","headers":_vm.controller.headers,"options":_vm.controller.options,"server-items-length":_vm.controller.codePagination.total,"items":_vm.controller.codePagination.items,"loading":_vm.controller.loading,"footer-props":{
      'items-per-page-options': [40, 80],
    }},on:{"update:options":function($event){return _vm.$set(_vm.controller, "options", $event)}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('div',[(_vm.hadPermission(5,2))?_c('v-btn',{staticClass:"px-2",attrs:{"icon":""},on:{"click":function($event){return _vm.controller.change(item)}}},[_c('v-icon',{attrs:{"color":"editBtn","small":""}},[_vm._v("mdi-pencil")])],1):_vm._e(),(_vm.hadPermission(5,3))?_c('v-btn',{staticClass:"px-2",attrs:{"icon":""},on:{"click":function($event){return _vm.controller.delete(item)}}},[_c('v-icon',{attrs:{"color":"deleteBtn","small":""}},[_vm._v("mdi-delete")])],1):_vm._e()],1)]}},{key:"top",fn:function(){return [_c('v-row',{staticClass:"pa-3",attrs:{"no-gutters":""}},[_c('v-col',{class:_vm.$vuetify.breakpoint.smAndDown
              ? 'py-3 d-flex align-center justify-center'
              : 'd-flex align-center',attrs:{"cols":"12","sm":"12","md":"6","lg":"6","xl":"6"}},[_c('span',{staticClass:"table-title"},[_vm._v("Códigos")])]),_c('v-col',{staticClass:"d-flex align-center justify-end",attrs:{"cols":"12","sm":"12","md":"6","lg":"6","xl":"6"}},[_c('v-text-field',{staticClass:"px-2 search-tf",staticStyle:{"max-width":"250px"},attrs:{"outlined":"","color":"primary","hide-details":"","dense":"","placeholder":"Pesquisar..."},model:{value:(_vm.controller.search),callback:function ($$v) {_vm.$set(_vm.controller, "search", $$v)},expression:"controller.search"}}),_c('v-dialog',{attrs:{"max-width":"600","persistent":"","scrollable":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [(_vm.hadPermission(5,1))?_c('v-btn',_vm._g(_vm._b({staticClass:"action-btn d-flex justify-center",attrs:{"height":"40","color":"primary","tile":""},on:{"click":function($event){$event.stopPropagation();return _vm.controller.open()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Adicionar ")],1):_vm._e()]}}]),model:{value:(_vm.controller.dialog),callback:function ($$v) {_vm.$set(_vm.controller, "dialog", $$v)},expression:"controller.dialog"}},[_c('v-card',{staticClass:"crud-card"},[_c('v-card-title',[_c('v-spacer'),_c('span',{staticClass:"table-title"},[_vm._v("Código")]),_c('v-spacer')],1),_c('v-card-text',{staticClass:"pt-3 pb-1"},[_c('v-form',{ref:"crud",attrs:{"lazy-validation":""}},[_c('v-row',[_c('v-col',[_c('div',{staticClass:"form-input-group"},[_c('v-text-field',{staticClass:"search-tf",attrs:{"single-line":"","id":"cod","rules":[function (v) { return !!v || 'Campo obrigatório'; }],"background-color":"transparent","outlined":"","required":"","dense":"","placeholder":"Código"},model:{value:(_vm.controller.form.name),callback:function ($$v) {_vm.$set(_vm.controller.form, "name", $$v)},expression:"controller.form.name"}}),_c('label',{attrs:{"for":"cod"}},[_vm._v("Código")])],1),_c('div',{staticClass:"form-input-group"},[_c('v-text-field',{staticClass:"search-tf",attrs:{"single-line":"","id":"password","rules":[
                            function (v) { return !!v || 'Campo obrigatório'; },
                            function (v) { return (!!v && v.length < 7) || 'Máximo 6 caracteres'; } ],"background-color":"transparent","outlined":"","required":"","dense":"","placeholder":"Senha"},model:{value:(_vm.controller.form.password),callback:function ($$v) {_vm.$set(_vm.controller.form, "password", $$v)},expression:"controller.form.password"}}),_c('label',{attrs:{"for":"password"}},[_vm._v("Senha")])],1)])],1)],1),_c('v-divider')],1),_c('v-card-actions',{staticClass:"px-6 pb-3"},[_c('v-btn',{staticClass:"action-btn d-flex justify-center",attrs:{"height":"36","width":"114","color":"primary","tile":"","outlined":""},on:{"click":function($event){return _vm.controller.close()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-close")]),_vm._v(" Cancelar ")],1),_c('v-spacer'),_c('v-btn',{staticClass:"action-btn d-flex justify-center",attrs:{"height":"36","width":"114","color":"primary","tile":"","loading":_vm.controller.loading},on:{"click":function($event){return _vm.controller.create()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-content-save")]),_vm._v(" Salvar ")],1)],1)],1)],1)],1)],1)]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }