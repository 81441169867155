export const headers = [
  {
    text: 'Nome',
    align: 'start',
    sortable: true,
    value: 'name',
    width: '80%'
  },
  {
    text: 'Versão Firmware',
    align: 'start',
    sortable: true,
    value: 'firmwareVersion',
    width: '10%'
  },
  {
    text: 'Ações',
    value: 'actions',
    align: 'right',
    sortable: false,
    width: '10%'
  }
]

