class Client {
  constructor(
    id,
    active,
    address,
    associate,
    cityID,
    cpfCnpj,
    name,
    nameAssociation,
    phone,
  ) {
    this.id = id
    this.active = active
    this.address = address
    this.associate = associate
    this.cityID = cityID
    this.cpfCnpj = cpfCnpj
    this.name = name
    this.nameAssociation = nameAssociation
    this.phone = phone
  }

}

export const clientDefault = () => {
  const client = {
    id: 0,
    active: true,
    address: "",
    associate: false,
    cityID: null,
    cpfCnpj: "",
    name: "",
    nameAssociation: "",
    phone: "",

  }
  return client
}

export default Client
