import dayjs from "dayjs"

class Release {
  constructor(
    startDate,
    endDate,
    accountID,
    clientID,
    modelID,
    vehicleID,
    codeID,
    equipmentID,
  ) {
    this.startDate = startDate
    this.endDate = endDate
    this.accountID = accountID
    this.clientID = clientID
    this.modelID = modelID
    this.vehicleID = vehicleID
    this.codeID = codeID
    this.equipmentID = equipmentID
  }

}

export const releaseDefault = () => {
  const release = {
    startDate: dayjs().startOf('month').format("YYYY-MM-DD"),
    endDate: dayjs().endOf('month').format("YYYY-MM-DD"),
    accountID: null,
    clientID: null,
    modelID: null,
    vehicleID: null,
    codeID: null,
    equipmentID: null,
  }
  return release
}

export default Release