import Handler from '@/core/domain/usecase/handler';

const deleteClientRepository = (axios) => async (id) => {
    try {
        const response = await axios.delete(`/client/${id}`)
        return response.data
    } catch (err) {
        if (err.isAxiosError) {
            throw new Handler().trataErro(err)
        } else {
            throw err
        }
    }
}

export default deleteClientRepository