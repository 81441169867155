<template>
  <div>
    <v-data-table
      class="astra-table"
      dense
      height="74vh"
      fixed-header
      :headers="controller.headers"
      :options.sync="controller.options"
      :server-items-length="controller.clientPagination.total"
      :items="controller.clientPagination.items"
      :loading="controller.loading"
      :footer-props="{
        'items-per-page-options': [40, 80],
      }"
    >
      <template v-slot:[`item.active`]="{ item }">
        <v-checkbox
          color="primary"
          class="mt-0 pt-0"
          v-model="item.active"
          hide-details
          readonly
          dense
        ></v-checkbox>
      </template>

      <template v-slot:[`item.associate`]="{ item }">
        <v-checkbox
          color="primary"
          class="mt-0 pt-0"
          v-model="item.associate"
          hide-details
          readonly
          dense
        ></v-checkbox>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <div>
          <v-btn
            icon
            @click="controller.change(item)"
            class="px-2"
            v-if="hadPermission(1,2)"
          >
            <v-icon
              color="editBtn"
              small
            >mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            icon
            @click="controller.delete(item)"
            class="px-2"
            v-if="hadPermission(1,3)"
          >
            <v-icon
              color="deleteBtn"
              small
            >mdi-delete</v-icon>
          </v-btn>
        </div>
      </template>

      <template v-slot:top>
        <v-row
          class="pa-3"
          no-gutters
        >
          <v-col
            :class="
              $vuetify.breakpoint.smAndDown
                ? 'py-3 d-flex align-center justify-center'
                : 'd-flex align-center'
            "
            cols="12"
            sm="12"
            md="6"
            lg="6"
            xl="6"
          >
            <span class="table-title">Clientes</span>
          </v-col>
          <v-col
            :class="
              $vuetify.breakpoint.smAndDown
                ? 'd-flex flex-column align-center justify-center'
                : 'd-flex align-center justify-end'
            "
            cols="12"
            sm="12"
            md="6"
            lg="6"
            xl="6"
          >
            <v-select
              outlined
              dense
              color="primary"
              v-model="controller.statusSelected"
              :items="controller.statusList"
              hide-details
              style="width: 200px"
              class="pa-2 search-tf"
              item-text="text"
              item-value="value"
              @change="controller.changeStatus()"
            ></v-select>
            <v-text-field
              class="pa-2 search-tf"
              style="width: 200px"
              outlined
              color="primary"
              hide-details
              dense
              placeholder="Pesquisar..."
              v-model="controller.search"
            ></v-text-field>
            <v-dialog
              max-width="600"
              persistent
              scrollable
              v-model="controller.dialog"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="hadPermission(1,1)"
                  class="action-btn d-flex justify-center"
                  height="40"
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  tile
                  @click.stop="controller.open()"
                >
                  <v-icon left>mdi-plus</v-icon>
                  Adicionar
                </v-btn>
              </template>
              <v-card class="crud-card">
                <v-card-title>
                  <v-spacer></v-spacer>
                  <span class="table-title">Cliente</span>
                  <v-spacer></v-spacer>
                </v-card-title>
                <v-card-text class="pt-3 pb-1">
                  <v-form
                    ref="crud"
                    lazy-validation
                  >
                    <v-row>
                      <v-col
                        cols="12"
                        class="d-flex"
                      >
                        <v-switch
                          v-model="controller.form.associate"
                          inset
                          label="Associado"
                        ></v-switch>
                        <v-spacer></v-spacer>
                        <v-switch
                          v-model="controller.form.active"
                          inset
                          label="Ativo"
                        ></v-switch>
                      </v-col>
                      <v-col>
                        <div
                          v-if="controller.form.associate"
                          class="form-input-group"
                        >
                          <v-text-field
                            class="search-tf"
                            single-line
                            id="associate"
                            :rules="[(v) => !!v || 'Campo obrigatório']"
                            background-color="transparent"
                            outlined
                            required
                            dense
                            placeholder="Associação"
                            v-model="controller.form.nameAssociation"
                          />
                          <label for="associate">Associação</label>
                        </div>
                        <div class="form-input-group">
                          <v-text-field
                            class="search-tf"
                            single-line
                            id="name"
                            :rules="[(v) => !!v || 'Campo obrigatório']"
                            background-color="transparent"
                            outlined
                            required
                            dense
                            placeholder="Nome"
                            v-model="controller.form.name"
                          />
                          <label for="name">Nome</label>
                        </div>
                        <div class="form-input-group">
                          <v-text-field
                            class="search-tf"
                            single-line
                            id="cpfcnpj"
                            :rules="[(v) => !!v || 'Campo obrigatório']"
                            background-color="transparent"
                            outlined
                            required
                            dense
                            placeholder="CPF/CNPJ"
                            v-model="controller.form.cpfCnpj"
                            v-mask="['###.###.###-##', '##.###.###/####-##']"
                          />
                          <label for="cpfcnpj">CPF/CNPJ</label>
                        </div>
                        <div class="form-input-group">
                          <v-text-field
                            class="search-tf"
                            single-line
                            id="phone"
                            background-color="transparent"
                            outlined
                            required
                            dense
                            placeholder="Telefone"
                            v-model="controller.form.phone"
                            v-mask="['(##) ####-####', '(##) #####-####']"
                          />
                          <label for="phone">Telefone</label>
                        </div>
                        <div class="form-input-group">
                          <v-text-field
                            class="search-tf"
                            single-line
                            id="address"
                            background-color="transparent"
                            outlined
                            required
                            dense
                            placeholder="Endereço"
                            v-model="controller.form.address"
                          />
                          <label for="address">Endereço</label>
                        </div>
                        <div class="form-input-group">
                          <v-autocomplete
                            class="search-tf"
                            single-line
                            id="address"
                            background-color="transparent"
                            outlined
                            required
                            dense
                            placeholder="Cidade"
                            v-model="controller.form.cityID"
                            :search-input.sync="controller.searchCity"
                            :items="controller.cityPagination.items"
                            item-text="name"
                            item-value="id"
                            :rules="[(v) => !!v || 'Campo obrigatório']"
                          />
                          <label for="address">Cidade</label>
                        </div>
                      </v-col>
                    </v-row>
                  </v-form>
                  <v-divider></v-divider>
                </v-card-text>
                <v-card-actions class="px-6 pb-3">
                  <v-btn
                    class="action-btn d-flex justify-center"
                    height="36"
                    width="114"
                    color="primary"
                    tile
                    outlined
                    @click="controller.close()"
                  >
                    <v-icon left>mdi-close</v-icon>
                    Cancelar
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    class="action-btn d-flex justify-center"
                    height="36"
                    width="114"
                    color="primary"
                    tile
                    @click="controller.create()"
                    :loading="controller.loading"
                  >
                    <v-icon left>mdi-content-save</v-icon>
                    Salvar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
      </template>
    </v-data-table>
  </div>
</template>

 <script>
import { clientController } from "@/di/di";
import { mask } from "vue-the-mask";
import _ from "lodash";

export default {
  name: "client-page",
  directives: { mask },
  data: (context) => ({
    controller: clientController(context),
  }),
  /*   mounted() {
    this.controller.watchCitySearch();
  }, */
  watch: {
    "controller.options"() {
      this.controller.watchOptions();
    },
    "controller.search"() {
      this.controller.watchSearch();
    },
    "controller.cityOptions"() {
      this.controller.watchCityOptions();
    },
    "controller.searchCity": _.debounce(function () {
      this.controller.watchCitySearch();
    }, 500),
  },
};
</script> 

<style scoped lang="scss">
.astra-table {
  border-radius: 20px;
  background-color: var(--v-secondary-base) !important;
}

.toolbar-table {
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
}

::v-deep .v-data-table.v-data-table--fixed-header thead th {
  background-color: var(--v-secondary-base) !important;
}

.action-btn {
  text-transform: none;
  border-radius: 10px !important;
}

.search-tf {
  border-radius: 10px !important;
}

.table-title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
}

.crud-card {
  border-radius: 20px;
  background-color: var(--v-secondary-base) !important;
}

::v-deep .v-dialog {
  border-radius: 20px !important;
}

label {
  order: -1;
}

.v-input:focus-within + label {
  color: var(--v-primary-base) !important;
}

.form-input-group {
  display: flex;
  flex-direction: column;
}

::v-deep tbody {
  tr:hover {
    background-color: var(--v-tertiary-base) !important;
  }
}
</style>
