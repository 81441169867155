export const headers = [
  {
    text: 'Data',
    align: 'start',
    sortable: true,
    value: 'createdDate',
    custom: true
  },
  {
    text: 'Placa',
    align: 'start',
    sortable: true,
    value: 'vehicleName',
  },
  {
    text: 'Serial',
    align: 'start',
    sortable: true,
    value: 'equipmentName',
  },
  {
    text: 'Rede',
    align: 'start',
    sortable: true,
    value: 'codeName',
  },
  {
    text: 'Cliente',
    align: 'start',
    sortable: true,
    value: 'clientName',
  },
  {
    text: 'Usuário',
    align: 'start',
    sortable: true,
    value: 'accountName',
  },
  {
    text: 'Motivo',
    align: 'start',
    sortable: true,
    value: 'reason',
  },
  {
    text: 'Descrição Motivo',
    align: 'start',
    sortable: true,
    value: 'descriptionReason',
  },
  {
    text: 'Observação',
    align: 'start',
    sortable: true,
    value: 'description',
  },
]
