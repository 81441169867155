import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import pt from "vuetify/src/locale/pt.ts";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    dark: true,
    themes: {
      light: {
        primary: {
          base: "#" + process.env.VUE_APP_PRIMARY_LIGHT,
        },
        secondary: {
          base: "#EEEEEE",
        },
        tertiary: {
          base: "#F5F5F5",
        },
        background: {
          base: "#E0E0E0",
        },
        editBtn: {
          base: '#82B1FF'
        },
        deleteBtn: {
          base: '#FF8A80'
        }
      },
      dark: {
        primary: {
          base: "#" + process.env.VUE_APP_PRIMARY_DARK,
        },
        secondary: {
          base: "#252040",
        },
        tertiary: {
          base: "#3B355F",
        },
        background: {
          base: "#171628",
        },
        editBtn: {
          base: '#82B1FF'
        },
        deleteBtn: {
          base: '#FF8A80'
        }
      },
    },
  },
  lang: {
    locales: { pt },
    current: "pt",
  }
});
